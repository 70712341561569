/**
 * @class
 */
export default class NetworkError extends Error {
    message;
    details;

    constructor(message, details) {
        super(message)
        this.name = 'NetworkError'
        this.message = message;
        this.details = details;
    }
}