<template>
  <v-layout class="grey lighten-5" v-resize="onResize">
    <v-navigation-drawer
      v-if="!roles.includes('admin')"
      v-model="drawerRight"
      width="400px"
      fixed
      right
      clipped
      app
      class="pa-0"
    >
      <Microcart />
    </v-navigation-drawer>

    <v-app-bar app fixed light clipped-right>
      <v-app-bar-nav-icon
        @click.prevent="toggleDrawer"
        light
      ></v-app-bar-nav-icon>
      <v-flex hidden-sm-and-down>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-flex>
      <v-flex xs5 sm6 md4 mt-4 ml-3>
        <v-text-field
          append-icon="mdi-magnify"
          light
          v-model="searchTerm"
          label="Search"
          placeholder="Type something"
          clearable
          @click:clear="clickClear"
          @change="handleSearch"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-md-and-up">
        <v-btn
          v-if="cartId"
          text
          light
          fab
          icon
          @click.stop="toggleCart"
          class="primary--text"
        >
          <v-icon>mdi-cart</v-icon>
        </v-btn>
        <v-btn text light fab icon @click.prevent="logoutUser">
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-menu offset-y v-if="productsCostsList.length > 0" max-height="400">
          <template v-slot:activator="{ on }">
            <v-btn color="grey lighten-2" v-on="on" icon>
              <v-badge top color="primary">
                <span slot="badge">{{ productsCostsList.length }}</span>
                <v-icon>mdi-currency-usd-off</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-list>
            <v-list-tile
              v-for="(item, index) in productsCostsList"
              :key="index"
              :to="{ name: 'productEdit', params: { sku: item.sku } }"
            >
              <v-list-tile-title>{{ item.name }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-menu
          offset-y
          v-if="adminMessages.length > 0"
          max-height="600"
          max-width="400"
          class="messages-from-admin"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey lighten-2" icon>
              <v-badge top color="primary">
                <span slot="badge">{{ adminMessages.length }}</span>
                <v-icon>mdi-message-text</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in adminMessages"
              :key="index"
              @click.prevent="showMoreMessage(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                  item.created_at | moment()
                }}</v-list-item-title>
                <v-list-item-subtitle
                  v-html="item.message"
                ></v-list-item-subtitle>
                <v-divider inset></v-divider>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="cartId"
          text
          light
          fab
          icon
          @click.prevent="toggleCart"
          class="primary--text"
        >
          <v-icon>mdi-basket</v-icon>
        </v-btn>

        <v-btn v-if="name" class="mx-3" text light :to="{ name: 'settings' }">{{
          name
        }}</v-btn>

        <v-btn text light @click.stop="logoutUser">Logout</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      disable-resize-watcher
      touchless
      v-model="drawer"
      fixed
      app
      overlay-color="red"
      color="secondary"
      :permanent="isShowDrawer"
      width="200"
      mobile-breakpoint="767"
      :mini-variant-width="miniVariantWidth"
      :mini-variant="miniVariant"
    >
      <sidebar></sidebar>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-snackbar v-model="notify" color="primary" right top>
      <span @click="showMoreMessage(_.last(adminMessages))">{{
        adminMessages.length > 0 ? _.last(adminMessages).message : ""
      }}</span>
      <v-btn @click.prevent="hideNotify()" text>
        OK
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="messagePopup" max-width="500">
      <v-card>
        <v-card-title>
          {{ messageObj.created_at | moment() }}
        </v-card-title>
        <v-card-text
          v-html="messageObj.message"
          class="ql-editor"
          v-linkified
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="messagePopup = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <terms-dialog
      v-if="
        !roles.includes('admin') && !roles.includes('manager') && termsDialog
      "
      :showOnLoad="true"
      v-on:answer="updateAnswer"
    />
    <v-snackbar
        v-for="(notification, index) in notifications"
        :key="index"
      v-model="notification.isShow"
      color="primary"
      :timeout="5000"
      right
      top
    >
      <div v-html="notification.msg"></div>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="notification.isShow = false"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="isShowMessage" max-width="750">
      <v-card>
        <v-card-title v-if="messageDialogObj.created_at">
          <div class="text-h5">
            {{ messageDialogObj.created_at | moment() }}
          </div>
        </v-card-title>
        <v-card-text
          v-if="messageDialogObj.message"
          class="d-flex justify-center"
        >
          <div
            class="text-subtitle-1"
            v-html="messageDialogObj.message"
            v-linkified
          ></div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            icon
            color="white"
            outlined
            v-if="showLeftSlider"
            @click="slideToTheLeft"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            dark
            small
            icon
            color="white"
            outlined
            v-if="showRightSlider"
            @click="slideToTheRight"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="closeMessageDialog"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Sidebar from "./components/Sidebar";
import Microcart from "@/components/cart/microcart";
import Cookies from "js-cookie";
import permission from "@/directive/permission/index.js";
import moment from "moment";
import TermsDialog from "@/components/Dialogs/TermsDialog";
import {getSearchParam} from "@/utils/getSearchParam";

export default {
  name: "layout",
  data: () => ({
    on: "",
    drawer: false,
    termsDialog: true,
    isShowDrawer: false,
    miniVariant: false,
    myTimer: false,
    isShowMessage: false,
    messageDialogObj: {},
    currentMessageIndex: 0,
    showLeftSlider: false,
    showRightSlider: true,

    userName: "",
    miniVariantWidth: 80,
    search: "",
    messagePopup: false,
    messageObj: {},
    notify: false,

    lastSearchInputDatetime: new Date(),
  }),
  directives: { permission },
  components: {
    Sidebar,
    Microcart,
    TermsDialog,
  },
  computed: {
    ...mapGetters([
      "name",
      "cartId",
      "roles",
      "productsCostsList",
      "adminMessages",
      "isAdmin",
      "isManager",
      "notifications",
      "getReportData",
      "userObject",
    ]),
    ...mapActions(["pushSuccessNotify", "checkReportGeneration"]),
    title() {
      let title = "Cockpit3D";
      if (this.$route.meta.pageTitle) {
        title = this.$route.meta.pageTitle;
      }
      return title;
    },
    report() {
      return this.getReportData;
    },
    searchTerm: {
      get() {
        return this.$store.getters.searchTerm;
      },
      set(newValue) {
        this.lastSearchInputDatetime = new Date();
        if (
          !this._.isNull(newValue) &&
          (newValue.length === 0 || newValue.length >= 3)
        ) {
          setTimeout(this.searchData, 1000, newValue);
        }
      },
    },
    drawerRight: {
      get() {
        return this.$store.getters.drawerRight;
      },
      set(value) {
        // this.$store.dispatch("drawerRightToggle", value);
        return false;
      },
    },
    messages() {
      return this.adminMessages !== undefined && this.adminMessages.length > 0
        ? _.orderBy(
            this.adminMessages,
            [
              function(object) {
                return new Date(object.created_at);
              },
            ],
            ["desc"]
          )
        : [];
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MM-DD-YY HH:mm");
    },
  },
  methods: {
    updateAnswer() {
      this.termsDialog = false;
    },
    searchData(value) {
      if (
        (value.length === 0 || value.length >= 3) &&
        new Date() - this.lastSearchInputDatetime >= 1000
      ) {
        this.$store.dispatch("setSearch", value);
      }
    },
    clickClear() {
      this.$store.dispatch("setSearch", "");
      this.searchTerm = '';
      this.setQueryParam('query', '');
    },
    slideToTheRight() {
      this.currentMessageIndex = this.currentMessageIndex + 1;
      this.transformSilder();
      this.messageDialogObj = this.messages[this.currentMessageIndex];
    },
    slideToTheLeft() {
      this.currentMessageIndex = this.currentMessageIndex - 1;
      this.transformSilder();
      this.messageDialogObj = this.messages[this.currentMessageIndex];
    },
    transformSilder() {
      if (this.currentMessageIndex < this.messages.length - 1) {
        this.showRightSlider = true;
      } else {
        this.showRightSlider = false;
      }

      if (this.currentMessageIndex > 0) {
        this.showLeftSlider = true;
      } else {
        this.showLeftSlider = false;
      }
    },
    showMoreMessage(item) {
      this.messageObj = item;
      this.messagePopup = true;
    },
    hideNotify() {
      Cookies.set("AdminMessagesHideId", this._.last(this.adminMessages).id);
      this.notify = false;
    },
    toggleDrawer() {
      let width = window.innerWidth;

      if (width < 767) {
        this.drawer = !this.drawer;
      } else if (width > 767 && width <= 1169) {
        this.miniVariant = !this.miniVariant;
      } else if (width > 1169) {
        this.miniVariant = !this.miniVariant;
      }
    },
    toggleCart() {
      this.$store.dispatch("drawerRightToggle", true);
    },

    onResize() {
      let width = window.innerWidth;

      if (width < 767) {
        this.isShowDrawer = false;
        this.miniVariant = true;
        this.miniVariantWidth = 150;
      } else if (width > 767 && width <= 1169) {
        this.isShowDrawer = true;
        this.miniVariantWidth = 80;
      } else if (width > 1169) {
        this.isShowDrawer = true;
      }
    },
    logoutUser() {
      Cookies.remove("IsShowedMessageDialog");
      this.$store.dispatch("LogOut").then(() => {
        location.reload();
      });
    },
    closeMessageDialog() {
      Cookies.set("IsShowedMessageDialog", true);
      this.isShowMessage = false;
    },
    handleSearch(newSearch) {
      this.$store.dispatch("setSearch", newSearch);
      this.setQueryParam('query', newSearch);

    },
    setQueryParam(parameter, value) {
      const query = { ...this.$route.query };
      if (value !== query[parameter]) {
        if (value) {
          query[parameter] = value;
        } else {
          delete query[parameter];
        }
        this.$router.push({ path: this.$route.path, query });
      }
    },
  },
  mounted() {
    this.$store.dispatch("checkReportGeneration");
    const param = getSearchParam('query')
    if (param) {
      this.$store.dispatch("setSearch", param);
    }
  },
  watch: {
    "report.file": {
      handler(data, oldVal) {
        if (data?.file)
          this.$store.dispatch("pushSuccessNotify", {
            msg: `Report is generated! <a class="notification-link" @click="pushSuccessNotify" href="${data.file}">Download</a>`,
          });
      },
    },
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (newQuery.search !== oldQuery.search) {
          const param = getSearchParam('query')
          if (param) {
            this.$store.dispatch("setSearch", param);
          }
        }
      },
      deep: true
    },
    messages: function(values) {
      if (values && values.length) {
        if (!this.isAdmin && !this.isManager) {
          if (!Cookies.get("IsShowedMessageDialog")) {
            this.messageDialogObj = values[this.currentMessageIndex];
            this.isShowMessage = true;
          }

          if (this.messages.length === 1) {
            this.showLeftSlider = false;
            this.showRightSlider = false;
          }
        }
      }
    },
  },
};
</script>

<style>
.messages-from-admin .v-chip {
  position: absolute;
  top: 0px;
  right: 305px;
}
.notification-link {
  color: #fff !important;
}
</style>
