<template>
  <v-main>
    <div v-if="globalLoader" class="progress-absolute">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-container fluid fill-height class="d-flex justify-center align-center">
      <v-row align-content="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12 text-center login-form">
            <h4 class="text-center text-h4">LOGIN</h4>

            <v-card-text>
              <v-form
                :model="loginForm"
                class="mb-5 mt-5"
                autocomplete="off"
                @keydown.native.enter="submit"
              >
                <v-text-field
                  v-validate="loginEmailValidator.validator"
                  v-model="loginForm.username"
                  :error-messages="errors.collect('email')"
                  :label="loginEmailValidator.label"
                  data-vv-name="email"
                  required
                  filled
                ></v-text-field>
                <v-text-field
                  v-validate="'required'"
                  v-model="loginForm.password"
                  :error-messages="errors.collect('password')"
                  label="Password"
                  :type="'password'"
                  data-vv-name="password"
                  required
                  filled
                ></v-text-field>
                <div class="text-center">
                  <v-btn large color="primary" @click="submit">Login</v-btn>
                </div>
              </v-form>
              <div class="text-center">
                <router-link class="white--text" :to="{ name: 'forgotPass' }"
                  >Forgot password?</router-link
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-snackbar
          v-model="loginError"
          color="primary"
          :timeout="5000"
          right
          top
          >Your Login or Password is incorrect
          <template v-slot:action="{ attrs }">
            <v-btn icon v-bind="attrs" @click="loginError = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "login",
  $validates: true,
  data: () => ({
    loginForm: {
      username: "",
      password: "",
    },
    loginError: false,
  }),
  computed: {
    ...mapActions(["setLoader"]),
    globalLoader() {
      return this.$store.getters.loader;
    },
    loginEmailValidator() {
      if (this.$route.name == "loginAdmin") {
        return { validator: "required", label: "Username" };
      } else {
        return { validator: "required|email", label: "E-mail" };
      }
    },
  },
  methods: {
    submit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$store.dispatch("setLoader", true);
          if (this.$route.name == "loginAdmin") {
            this.$store
              .dispatch("loginAdmin", this.loginForm)
              .then(() => {
                this.$router.push({ path: "/" }, () => {});
              })
              .catch((error) => {
                this.loginError = true;
              })
              .finally(() => {
                this.$store.dispatch("setLoader", false);
              });
          } else {
            this.$store
              .dispatch("login", this.loginForm)
              .then(() => {
                try {
                  this.$router.go("/");
                } catch {
                  this.loginError = true;
                }
              })
              .catch((error) => {
                this.loginError = true;
              })
              .finally(() => {
                this.$store.dispatch("setLoader", false);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
.login-form {
  padding: 30px 15px;
}

.login-form a {
  color: #d9d9d8;
}

.link {
  list-style-type: none;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
