/**
 * @class
 */
export default class StockItem {
    sku;
    sourceCode;
    availableQty;
	needToDeductQty;

	/**
	 * @constructor
	 * @param {*} sku 
	 * @param {*} sourceCode 
	 * @param {*} availableQty 
	 * @param {*} needToDeductQty 
	 */
	constructor(sku, sourceCode, availableQty, needToDeductQty) {
		this.sku = sku;
		this.sourceCode = sourceCode;
		this.availableQty = availableQty;
		this.needToDeductQty = needToDeductQty;
	}

}
