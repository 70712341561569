import Product from "./product";

/**
 * @class
 */
export default class Cart {
    
    /**
     * @property {Product} products
     */
    products;

    address;

    stockId;

    sortedLaserhubs;

    /**
     * @constructor
     * @param {*} products 
     * @param {*} address 
     * @param {*} stockId 
     * @param {*} sortedLaserhubs 
     */
    constructor(products, address, stockId, sortedLaserhubs) {
        this.products = products;
        this.address = address;
        this.stockId = stockId;
        this.sortedLaserhubs = sortedLaserhubs;
    }

}
