import axios from 'axios'
import Qs from 'qs'
import {getToken, getAdminToken, removeToken, removeAdminToken} from '@/utils/auth'
import store from '@/store'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    paramsSerializer: function (params) {
        return Qs.stringify(params, {encodeValuesOnly: true})
    }
})

service.interceptors.request.use(config => {
    if (getToken()) {
        config.headers['Authorization'] = 'Bearer ' + getToken()
    } else if (getAdminToken()) {
        config.headers['Authorization'] = 'Bearer ' + getAdminToken()
    }
    config.headers['Content-Type'] = 'application/json'
    return config
}, error => {

    console.log(error)
    Promise.reject(error)
})


service.interceptors.response.use(
    response => {
        store.dispatch('setErrorNotify', false)
        return response
    },
    error => {
        if (error.response.status === 401 && error.response.statusText == 'Unauthorized') {
            removeToken()
            removeAdminToken()
        } else if (error.response.status === 500) {
            store.dispatch('setErrorNotify', true)
        }

        return Promise.reject(error)
    })

export default service
