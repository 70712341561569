import {categories, productAttributes, bundleSimpleProducts} from '@/api/products'
import {filterAttributes} from '@/utils/filter'

const products = {
    namespaced: true,
    state: {
        categoryList: [],
        attributes: [],
        bundleOptionsTitle: [
            {label: 'Size', value: 'size'},
            {label: 'Keychain', value: 'keychains'},
            {label: 'Heart Keychains', value: 'heart_keychain'},
            {label: 'Rectangle Keychains', value: 'rectangle_keychain'},
            {label: 'Light Base', value: 'light_base'},
            {label: 'Customer Text', value: 'customer_text'},
            {label: 'Custom Design', value: 'custom_design'},
            {label: 'Custom Option', value: 'custom_option'},
            {label: 'Face', value: 'face'},
            {label: 'Keep background', value: 'keep_bg'},
            {label: '2D Backdrop', value: 'two_backdrop'},
            {label: '3D Backdrop', value: 'three_backdrop'},
            {label: 'Design Work', value: 'design_work'},
            {label: 'Photo restoration of old photos', value: 'photo_restoration'},
            {label: '24h/48h service', value: 'service_queue'},
            {label: 'For digital preview', value: 'digi_preview'},
            {label: 'Laser Engraved Personal Note', value: 'laser_engraved_text'},
            {label: 'Clip art', value: 'disney'}
        ]
    },

    mutations: {
        SET_CATEGORIES: (state, categories) => {
            state.categoryList = categories
        },
        SET_ATTRIBUTES: (state, items) => {
            state.attributes = items
        },
    },

    actions: {
        mainLoading({dispatch}) {
            dispatch('getCrystalAttributes')
            dispatch('categoryList')
        },
        categoryList({commit, state, dispatch}, id = 'all') {
            return new Promise((resolve, reject) => {
                categories(id).then(response => {
                    commit('SET_CATEGORIES', response.data)
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getCrystalAttributes({commit, state}) {
            let id = process.env.VUE_APP_CRYSTAL_ATTR_ID
            return new Promise((resolve, reject) => {
                productAttributes(id).then(response => {
                    const data = response.data
                    if (data) {
                        let items = []
                        items.push({key: id, options: filterAttributes(data)})
                        commit('SET_ATTRIBUTES', items)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
}

export default products
