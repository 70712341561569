import request from '@/utils/request'

export function loginByUsername(username, password) {
  const data = {
    username,
    password
  }
  
  return request({
    url: '/integration/customer/token',
    method: 'post',
    data
  })
}

export function loginAdminByUsername(username, password) {
  const data = {
    username,
    password
  }
  
  return request({
    url: '/integration/admin/token',
    method: 'post',
    data
  })
}

export function logout() {
  
  return request({
    url: '/api/auth/logout',
    method: 'post',
    
  })
}

export function getUserInfo() {
  
  return request({
    url: '/customers/me',
    method: 'get'
   
  })
}

export function getUserInfoShipping() {
  
  return request({
    url: '/customers/me/shippingAddress',
    method: 'get'
   
  })
}

export function getCustomerAvatar(id) {
  
  return request({
    url: `/customer-avatar/${id}`,
    method: 'get'
   
  })
}
export function setCustomerAvatar(data) {
  
  return request({
    url: `/customer-avatar/save`,
    method: 'post',
    data
  })
}

