<template>
  <div v-if="isCustomer" style="display: inline-block">
    <v-dialog
        persistent
        v-model="terms_dialog"
        width="700px"
    >
      <v-card>
        <v-card-title>
          <span  class="text-h5">Terms and Conditions</span>
        </v-card-title>
        <v-card-text>
          <div v-html="content"></div>
          <v-checkbox
              v-model="innerCheckbox"
          >
            <div slot='label'>I've read the document and I accept the terms</div>
          </v-checkbox>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              v-on:click="disAgree"
          >
            Disagree
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              :disabled="!innerCheckbox"
              :loading="loading"
              v-on:click="agree"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import {agreeTermsAndConditions, getTermsAndConditionsText} from "@/api/user";
import {mapGetters} from "vuex";

export default {
  props: {
    showOnLoad: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      terms_dialog: false,
      loading: false,
      content: '',
      innerCheckbox: false
    }
  },
  mounted() {
    this.getTermsText()
    this.terms_dialog = !this.isTermsAgree && this.showOnLoad;
  },
  computed:{
    ...mapGetters(['isTermsAgree', 'roles','userObject','isProfit','isCustomer']),
  },
  methods: {
    check($event) {
      this.terms_dialog = true
    },
    disAgree(){
      this.terms_dialog = false;
      this.$emit('answer', false)
    },
    async agree(){
      if(this.showOnLoad) {
        await this.termsAgree()
        await this.$store.dispatch("GetUserInfo");
      }
      this.terms_dialog = false;
      this.$emit('answer', true)
    },
    async getTermsText () {
      let self = this
      await new Promise((resolve, reject) => {
        getTermsAndConditionsText().then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      }).then(function (result) {
        self.content = result
      })
    },
    async termsAgree () {
      this.loading = true
      let self = this

      await new Promise((resolve, reject) => {
        agreeTermsAndConditions().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      }).then(function (result) {
        self.loading = false
      })
    }
  },
  watch:{
    '$route.query.terms'(terms) {
        if(terms)
          this.terms_dialog = true
        else
          this.terms_dialog = false
    }
  }
}
</script>
