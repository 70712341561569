import Vue from 'vue'
import Vuex from 'vuex'
import permission from './modules/permission'
import user from './modules/user'
import products from './modules/products'
import cart from './modules/cart'
import search from './modules/search'
import report from './modules/report'
import others from './modules/others'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
  	permission,
    user,
    products,
    cart,
    search,
    others,
    report
  },
  getters
})

export default store
