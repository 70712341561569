import LaserhubsRepository from "../../domain/repositories/laserhubs_repository";
import LaserhubsDatasource from "../datasources/laserhubs_datasource";
import CartDto from "../dtos/cart_dto";
import CantFetchLaserhubsError from "../../../../core/network_errors/laserhub/cant_fetch_laserhubs";

/**
 * @class
 */
export default class LaserhubsRepositoryImpl extends LaserhubsRepository {

    /**
     * @property {LaserhubsDatasource} laserhubsDatasource
     */
    laserhubsDatasource;

    /**
     * @constructor
     * @param {*} laserhubsDatasource 
     */
    constructor(laserhubsDatasource) {
        super();
        this.laserhubsDatasource = laserhubsDatasource
    }

    /**
     * 
     * @param {*} items 
     * @param {*} address 
     * @param {*} stockId 
     * @param {*} allLaserhubs 
     * @returns {Cart}
     */
    async fetchCartWithStockItems(items, address, stockId, allLaserhubs) {
        let response;
        try {
            response = await this.laserhubsDatasource.fetchStockData(items, address, stockId)
        } catch(error) {
            return new CantFetchLaserhubsError()
        }
        const cartDto = new CartDto(items, address, stockId, allLaserhubs, response)

        return cartDto.toCart()
    }

}