/**
 * @class
 */
export default class Product {

    constructor(){}
    
    isAvailable(){}

}
