import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VeeValidate from 'vee-validate'
import VueLodash from 'vue-lodash'
import VueQriously from 'vue-qriously'
import AsyncComputed from 'vue-async-computed'
import Vue2Filters from 'vue2-filters'
import moment from 'vue-moment'
import VDateRange from 'vuetify-daterange-picker'
import VueIntro from 'vue-introjs'
import lodash from 'lodash'
import goTo from 'vuetify/lib/services/goto'
import VueHead from "vue-head";
import { VueMaskDirective } from 'v-mask'
import linkify from 'vue-linkify'
import 'quill/dist/quill.core.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as VueGoogleAutocomplete from 'vue-google-autocomplete'


import 'vuetify-daterange-picker/dist/vuetify-daterange-picker.css'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
// import '@mdi/font/css/materialdesignicons.css'

import './permission'

import 'intro.js/introjs.css'

import './css/app.css'

const veeConf = {
  events: '',
};

Vue.use(VeeValidate, veeConf)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueQriously)
Vue.use(AsyncComputed)
Vue.use(Vue2Filters)
Vue.use(moment)
Vue.use(VDateRange)
Vue.use(VueGoogleMaps)
Vue.use(VueIntro, {
  waitTimeout: 1000
})

let api = {
  key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
  libraries: 'places,drawing,visualization',
}

VueGoogleMaps.loadGmapApi(api)
Vue.use(VueHead);
Vue.directive('linkified', linkify);
Vue.directive('mask', VueMaskDirective);
Vue.filter('momenttimezone', function (value, format) {
  return Vue.moment.utc(String(value)).local().format(format);
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
