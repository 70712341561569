import Product from "./product";

/**
 * @class
 */
export default class Bundle extends Product {
    
	/**
	 * @property {BundleItem[]}
	 */
    items = [];

	/**
	 * 
	 * @param {*} hub 
	 * @returns {bool}
	 */
	isAvailable(hub) {
		for (const item of this.items) {
			if (!item.isAvailable(hub)) {
				return false;
			}
		}

		return true;
	}	
}
