import request from '@/utils/request'

export function getItemsList(id, token) {

    return request({
        url: "/order-item-photo/itemListWithoutPhoto/" + id + "/" + token,
        method: 'get'
    })
}

export function getItemsWithPhotoList(id, token) {

    return request({
        url: "/order-item-photo/itemList/" + id + "/" + token,
        method: 'get'
    })
}

export function getOrderItems(id) {

    return request({
        url: "/custom-orders/" + id,
        method: 'get'
    })
}

export function getStatuses() {
    return request({
        url: "/all-orders-status/",
        method: "get"
    });
}

export function getCrystalStylePhots() {
    let data = {
        store: process.env.VUE_APP_STORE_ID
    };

    return request({
        url: '/all-bundle-shape-photo',
        method: 'get',
        params: data
    })
}

export function getCustomerOrders(data) {
    let query = {
        searchCriteria: {
            currentPage: data.pageNumber,
            pageSize: data.pageSize,
            sortOrders: [
                {
                    field: data.sortBy,
                    direction: data.descending ? 'DESC' : 'ASC'
                }

            ],
            filterGroups: data.filter
        }
    }
    return request({
        url: '/customer-orders',
        method: 'get',
        params: query,
    })
}

export function getReadyReport(data) {
    return request({
        url: '/get-ready-report',
        method: 'get',
        params: {id:data}
    })
}

export function downloadCustomerOrders(data, website) {

    let query = {
        searchCriteria: {
            sortOrders: [
                {
                    field: data.sortBy,
                    direction: data.descending ? 'DESC' : 'ASC'
                }

            ],
            filterGroups: data.filter
        }
    }

    let url = '';
    switch(website) {
        case 'lds':
            url = '/lds-export-orders';
            break;
        case 'rccl':
            url = '/rccl-export-orders';
            break;
        case 'ncl':
            url = '/ncl-export-orders';
            break;
        default:
            url = '/export-orders';
            break;
    }

    return request({
        url: url,
        method: 'get',
        params: query,
    })
}

export function setItemPhotos(item, photoIds = []) {
    let data = {
        origin: {
            id: (!photoIds || photoIds.length < 1) ? 0 : photoIds[0],
            order_item_id: item.id,
            token: item.token,
            content: item.imageOrigin.shift()
        },
        crop: {
            id: (!photoIds || photoIds.length < 1) ? 0 : photoIds[1],
            order_item_id: item.id,
            token: item.token,
            content: item.imageCrop.shift()
        }
    };
    return request({
        url: '/order-item-photo/save',
        method: 'post',
        data
    });
}

export function setTryNowItemPhotos(item) {
    let data = {
        origin: {
            product_id: item.crystal_id,
            content: item.imageOrigin.shift()
        },
        crop: {
            product_id: item.crystal_id,
            content: item.imageCrop.shift()
        }
    };
    return request({
        url: '/try-now/save',
        method: 'post',
        data
    });
}

export function addDiscount(item) {
    let data = item

    return request({
        url: '/quote-discount/saveQuoteItemDiscount',
        method: 'post',
        data
    });
}

export function addQuoteDiscount(item) {
    let data = item

    return request({
        url: '/quote-discount/saveQuoteDiscount',
        method: 'post',
        data
    });
}

export function setOrderStatusForGroupIsReceived(items) {
    let data = {
        orderIds: items
    }

    return request({
        url: '/hq-orders/set-order-status-is-received',
        method: 'post',
        data
    });
}

export function setOrderStatusIsReceived(items) {
    let data = {
        orderIds: items
    }

    return request({
        url: '/hq-orders/set-order-item-status-is-received',
        method: 'post',
        data
    });
}

export function setOrderStatusForGroupIsCollectedByCustomer(items) {
    let data = {
        orderIds: items
    }

    return request({
        url: '/hq-orders/set-order-status-is-collected_by_customer',
        method: 'post',
        data
    });
}

export function setOrderStatusIsCollectedByCustomer(items) {
    let data = {
        orderIds: items
    }

    return request({
        url: '/hq-orders/set-order-item-status-is-collected_by_customer',
        method: 'post',
        data
    });
}

export function resendLink(item) {
    let data = item

    return request({
        url: '/order-item-photo/resend',
        method: 'post',
        data
    });
}

export function deleteSelectedOrders(orderIds) {
    let data = {
        order_ids: orderIds
    }
    return request({
        url: '/custom-orders/delete',
        method: 'post',
        data
    });
}

export function syncSelectedOrders(orderIds) {
    let data = {
        order_ids: orderIds
    }
    return request({
        url: '/hq-orders/send-to-hq',
        method: 'post',
        data
    });
}

export function sendREDOOrder(obj) {
    let data = {data: obj}
    return request({
        url: '/hq-create-redo',
        method: 'post',
        data
    });
}

export function sendPrepaidExpense(id) {
    let data = {id: id}
    return request({
        url: '/custom-order/layaway-paid/',
        method: 'post',
        data
    });
}

export function sendOrderToEdit(id) {
    let data = {}
    return request({
        url: `/edit-orders/${id}/edit`,
        method: 'post',
        data
    });
}

export function saveOrderOnly(data) {
    return request({
        url: '/custom-carts/mine/place-order',
        method: 'post',
        data
    })
}

export function changeLaserhubInOrder(id, code) {
    return request({
        url: `edit-source-code/order/${id}/source-code/${code}`,
        method: 'put',
    })
}
