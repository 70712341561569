import request from '@/utils/request'

export function emailsList() {
    
    return request({
        url: 'hq-seller-email-message/list',
        method: 'get',
    })
}
export function emailsSave(list) {
    let data = list
    return request({
        url: 'hq-seller-email-message/save',
        method: 'post',
        data
    })
}

export function sendStaffPassword(password) {
    let data = {
        password: password
    }
    return request({
        url: '/two-password/save-password',
        method: 'post',
        data
    })
}




export function createNewOrEditStaffUser(obj) {
    let data = {
        staff: obj
    }
    return request({
        url: '/staff-users/save',
        method: 'post',
        data
    })
}

export function getAllStaffUsers() {

    return request({
        url: '/staff-users/all-list',
        method: 'get',
    })
}

export function getActiveStaffUsers() {

    return request({
        url: '/staff-users/list',
        method: 'get',
    })
}

export function deleteStaffUser(id) {
    let data = {
        id: id
    }
    return request({
        url: '/staff-users/delete',
        method: 'post',
        data
    })
}

export function getAdminStatistic(obj) {
    let data = {
        period: obj
    }
    return request({
        url: '/statistics/admin',
        method: 'post',
        data
    })
}

export function getSellerStatistic(obj) {
    let data = {
        period: obj
    }
    return request({
        url: '/statistics/seller',
        method: 'post',
        data
    })
}

export function agreeTermsAndConditions() {
    return request({
        url: '/terms-and-conditions/agree',
        method: 'get'
    })
}

export function getTermsAndConditionsText() {
    return request({
        url: '/terms-and-conditions',
        method: 'get'
    })
}
