import RetriveAvailableLaserhubsParameters from "./parameters/retrieve_available_laserhubs_parameters";
import LaserhubsRepository from "../repositories/laserhubs_repository";
import AvailableLaserhubsSelector from "../../../../services/laserhub/available_laserhubs_selector";

// ToDo: Rewrite to callable class
export default class LaserhubsUseCase {

  /**
   * @property {LaserhubsRepository} laserhubsRepository
   */
  laserhubsRepository;

  /**
   * @param {AvailableLaserhubsSelector} availableLaserhubsSelector
   */
  availableLaserhubsSelector;

  /**
   * 
   * @param {LaserhubsRepository} laserhubsRepository 
   * @param {AvailableLaserhubsSelector} availableLaserhubsSelector 
   * @returns 
   */
  constructor(laserhubsRepository, availableLaserhubsSelector) {
    this.laserhubsRepository = laserhubsRepository;
    this.availableLaserhubsSelector = availableLaserhubsSelector;
  }

  /**
   * 
   * @param {RetriveAvailableLaserhubsParameters} parameters 
   * @returns 
   */
  async run(parameters) {
    const cart = await this.laserhubsRepository.fetchCartWithStockItems(parameters.cartItems, parameters.address, parameters.stockId, parameters.allLaserhubs);

    return this.availableLaserhubsSelector.retrieveAvailableLaserhubs(cart);
  }

}