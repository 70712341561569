import Product from "./product";

/**
 * @class
 */
export default class Simple extends Product {

    sku;
    qty;
    
	/**
	 * @property {StockItem[]}
	 */
    stock;

	/**
	 * @constructor
	 * @param {*} sku 
	 * @param {*} qty 
	 * @param {*} stock 
	 */
	constructor(sku, qty, stock) {
		super(); 
		this.sku = sku;
		this.qty = qty;
		this.stock = stock;
	}

	/**
	 * 
	 * @param {*} hub 
	 * @returns {boolean}
	 */
	isAvailable(hub) {
		let key = hub + '_' + this.sku;
		const item = this.stock.get(key)
		return item.availableQty > this.qty;
	}
}