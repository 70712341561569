import NetworkError from "../network_error";

/**
 * @class
 */
export default class CantFetchLaserhubsError extends NetworkError {

    constructor() {
        super('Cant Fetch Laserhubs')
    }
}