<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-title class="text-h5">Add discount</v-card-title>

      <v-card-text>
        <p class="percent-or-dollar px-3">Dollar <v-switch v-model="isPercent"></v-switch> Percent</p>

        <v-text-field
          v-model="amount"
          dark
          clearable
          class="pa-3"
          type="number" 
          step="any"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.prevent="removeDiscount" v-if="amount >= 0">Remove</v-btn>
        <v-btn color="primary" text @click.prevent="dialog = false">Cancel</v-btn>

        <v-btn color="primary" @click.prevent="saveDiscount">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addQuoteDiscount } from "@/api/orders";
import { mapGetters } from 'vuex';
export default {
  name: "discountCartAmount",
  data() {
    return {
      dialog: false,
      amount: "",
      isPercent: true,
    };
  },
  methods: {
    saveDiscount() {
      let item = {
        discounts: []
      };
      item.discounts.push({
        quote_id: this.cartId,
        type: (this.isPercent == 1)? "percentage" : "amount",
        value: this._.toNumber(this.amount)
      });
      this.dialog = false;
      return new Promise((resolve, reject) => {
        addQuoteDiscount(item)
          .then(response => {
            const data = response.data;
            this.$store.dispatch("getCartItems");
            this.$store.dispatch("getCartTotalSum");
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    removeDiscount() {
      let item = {
        discounts: []
      };
      item.discounts.push({
        quote_id: this.cartId,
        type: "amount",
        value: 0
      });
      this.dialog = false;
      return new Promise((resolve, reject) => {
        addQuoteDiscount(item)
          .then(response => {
            const data = response.data;
            this.$store.dispatch("getCartItems");
            this.$store.dispatch("getCartTotalSum");
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
 computed:{
     ...mapGetters(['cartId'])
 }
};
</script>

<style scoped>
  .percent-or-dollar {
    display: inline-flex;
    margin: 0 auto;
  }

  .percent-or-dollar .v-input {
    margin: 0;
    padding: 0 10px;
    height: 25px;
  }
</style>