import request from '@/utils/request'

export function categories(id) {

  return request({
    url: '/catalog/' + id,
    method: 'get'
  })
}

export function productsList(data) {

  let query = {
    searchCriteria: {
      currentPage: data.pageNumber,
      pageSize: data.pageSize,
      sortOrders: [
        {
          field: data.sortBy,
          direction: data.descending ? 'DESC' : 'ASC'
        }

      ],
      filterGroups: data.filter
    }
  }

  return request({
    url: '/products',
    method: 'get',
    params: query,
    // baseURL: '/api/rest/all/V1',
  })
}

export function productsEditList(data) {
  let query = {
    searchCriteria: {
      currentPage: data.pageNumber,
      pageSize: data.pageSize,
      sortOrders: [
        {
          field: data.sortBy,
          direction: data.descending ? 'DESC' : 'ASC'
        }
      ],
      filterGroups: data.filter
    }
  }

  return request({
    url: '/customer-price/list',
    method: 'get',
    params: query,
  })
}

export function product(sku) {

  return request({
    url: '/custom-product-info/' + sku,
    method: 'get'
  })
}
export function productType() {

  return request({
    url: '/products/types',
    method: 'get'
  })
}

export function productEdit(sku) {

  return request({
    url: '/products/' + sku,
    method: 'get',
    baseURL: '/api/rest/all/V1',
  })
}
export function deleteProductApi(sku) {

  return request({
    url: '/products/' + sku,
    method: 'delete'
  })
}
export function productCreate(product) {
  
  let data = {
    product:product
  }
  if (product.type_id == 'bundle') {
    data.saveOptions = true;
  }
  return request({
    url: '/products',
    method: 'post',
    baseURL: '/api/rest/all/V1',
    data
  })
}

export function productsEditListSave(product) {

  let data = {
    prices:product
  }

  return request({
    url: '/customer-price/save',
    method: 'post',
    data
  })
}
export function productUpdate(product) {
  let data = {
    product:product
  }
  let sku = product.sku
  if (product.type_id == 'bundle') {
    data.saveOptions = true;
  }
  return request({
    url: '/products/' + sku,
    method: 'put',
    baseURL: '/api/rest/all/V1',
    data
  })
}
export function productSaveTiers(prices) {
  let data = {
    prices:prices
  }
  return request({
    url: `/products/save-all-tiers`,
    method: 'post',
    baseURL: '/api/rest/all/V1',
    data
  })
}
export function productEditTiers(sku) {

  return request({
    url: `/products/${sku}/all-tiers` ,
    method: 'get'
  })
}

export function productCategories() {
  let query = {
    searchCriteria: {
      currentPage: 1,
      pageSize: 15,
      sortOrders: [],
      filterGroups: [{
        filters: []
      }]
    }
  }
  return request({
    url: 'categories/list',
    method: 'get',
    params: query
  })
}

export function productHqProductId() {
  return request({
    url: 'products/attributes/hq_product_id',
    method: 'get'
  })
}

export function productLightbaseSize() {
  return request({
    url: '/products/attributes/lightbase_size',
    method: 'get'
  })
}

export function productChildrens(sku) {

  return request({
    url: '/configurable-products/' + sku + '/children',
    method: 'get'
  })
}

export function bundleSimpleProducts(sku) {

  return request({
    url: '/custom-product-info/' + sku,
    method: 'get'
  })
}

export function productAttributes(code) {

  return request({
    url: '/products/attribute-sets/' + code + '/attributes',
    method: 'get'
  })
}
export function attributeSets() {
  let query = {
    searchCriteria: {
      currentPage: 1,
      sortOrders: [],
      filterGroups: [{
        filters: []
      }]
    }
  }
  return request({
    url: '/products/attribute-sets/sets/list',
    method: 'get',
    params: query
  })
}

export function quotesGet(data) {
  
  let query = {
    searchCriteria: {
      currentPage: data.pageNumber,
      pageSize: data.pageSize,
      sortOrders: [
        {
            field: data.sortBy,
            direction: data.descending ? 'DESC' : 'ASC'
        }

      ],
      filterGroups: data.filter
    }
  }
 
  


  return request({
    url: '/customer-quotes',
    method: 'get',
    params: query,
  })
}

export function quoteStatusSet(id, status) {

  return request({
    url: '/update-quote-status/' + id + '/status/' + status,
    method: 'post'
  })
}
export function uploadShapeFile(file) {
  let data = {file:file}
  return request({
    url: '/product-file-attribute/save',
    method: 'post',
    data
  })
}
export function deleteQuouteApi(id) {
  return request({
    url: `custom-cart/delete/${id}`,
    method: 'get'
  })
}

export function newIndexProduct(data) {
  return request({
    url: '/catalog/order',
    method: 'post',
    data
  })
}