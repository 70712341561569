/**
 * @class
 */
export default class RetriveAvailableLaserhubsParameters {

    cartItems;
    address;
    stockId;
    allLaserhubs;

    /**
     * @constructor
     * @param {*} cartItems 
     * @param {*} address 
     * @param {*} stockId 
     * @param {*} allLaserhubs 
     */
    constructor(cartItems, address, stockId, allLaserhubs) {
        this.cartItems = cartItems;
        this.address = address;
        this.stockId = stockId;
        this.allLaserhubs = allLaserhubs;
    }

}
