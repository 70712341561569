import request from '@/utils/request'

export function cartAdd() {
  return request({
    url: '/carts/mine',
    method: 'post'
  })
}

export function cartGet() {
  return request({
    url: '/carts/mine',
    method: 'get'
  })
}

export function cartGetItems() {
  return request({
    url: '/carts/mine/items', 
    method: 'get'
  })
}

export function cartGetById(id) {
  return request({
    url: '/customercarts/'+id+'/items', 
    method: 'get'
  })
}

export function ordersGet() {
  return request({
    url: '/orders',
    method: 'get'
  })
}

export function getCartSumTotal() {
  return request({
    url: '/carts/mine/totals',
    method: 'get'
  })
}

export function cartAddItems(item) {
  const data = {
    cartItem:item
  }
  return request({
    url: '/carts/mine/items',
    method: 'post',
    data
  })
}

export function updateCartItem(item, type) {
  const data = {
    cartItem:item
  }

  if (type == 'put') {
    delete data.cartItem.extension_attributes.customer_photo;
      return request({
          url: '/carts/mine/items/' + item.item_id,
          method: 'put',
          data
      })
  }
  else {
      return request({
          url: '/carts/mine/items/' + item.item_id,
          method: 'delete'
      })
  }
}

export function ordersList(mail) {
  return request({
    url: '/orders',
    method: 'get',
    params: {
      search_criteria:{
        filter_groups:[{
          filters:[{
            field:'email',
            value:mail,
            condition_type:'like'
          }]
        }]
      }
    }
  })
}

export function getAllInventories(){
  return request({
    url:`/customer/inventory/sources`,
    method:'get',
  })
}

export function getStockId(){
  return request({
    url:`/customer/inventory/stock-resolver/website/base`,
    method:'get',
  })
}

export function getClosestAddress (data) {
  return request({
    url:`/customer/inventory/source-selection-algorithm-result`,
    method: 'post',
    data
  })
}