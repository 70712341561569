import request from '@/utils/request'

export function cartCustomers(data) {
  let query = {
    searchCriteria: {
      currentPage: data.pageNumber,
      pageSize: data.pageSize,
      sortOrders: [
        {
          field: data.sortBy,
          direction: data.descending ? 'DESC' : 'ASC'
        }

      ],
      filterGroups: data.filter
    }
  }

  return request({
    url: '/customers/search',
    method: 'get',
    params: query
  })
}

export function allCustomers() {
  return request({
    url: '/all-customers',
    method: 'get'
  })
}
export function getCustomer(id) {
  return request({
    url: '/customers/' + id,
    method: 'get'
  })
}

export function createCustomerGroup(group) {

  let data = {
    group: group
  }
  return request({
    url: '/customerGroups/',
    method: 'post',
    data
  })
}
export function getCustomerGroup(id) {
  return request({
    url: `/customerGroups/${id}`,
    method: 'get'
  })
}
export function checkCostIsCorrectApi() {
  return request({
    url: '/check-cost-is-correctly',
    method: 'get'
  })
}
export function updateCustomerGroup(group) {
  let id = group.id;
  let data = {
    group: group
  }
  return request({
    url: `/customerGroups/${id}`,
    method: 'put',
    data
  })
}
export function updateCustomerGroupProductTierPrice(product) {
  let data = product;
  return request({
    url: '/cga/product-group-tire-price',
    method: 'post',
    data
  })
}

export function deleteCustomerGroupProductTierPrice(groupId, productId, qty) {
  return request({
    url: `/cga/product-group-tire-price/${groupId}/${productId}/${qty}`,
    method: 'delete'
  })
}
export function updateCustomerGroupProductFreeFaces(product) {
  let data = product;
  return request({
    url: '/cga/save-product-group-free-face',
    method: 'post',
    data
  })
}
export function cloneNewGroupProducs(group) {
  let data = group;
  return request({
    url: '/customerGroups/copy',
    method:'post',
    data
  })
} 
export function deleteCustomerGroup(id) {
  return request({
    url: `/customerGroups/${id}`,
    method: 'delete'
  })
}

export function getCustomerGroups() {
  let query = {
    searchCriteria: {
      sortOrders: [
        {
          field: 'customer_group_id'
        }
      ]
    }
  }
  return request({
    url: '/customerGroups/search',
    method: 'get',
    params: query
  })
}

export function updateCustomer(obj) {

  const data = { customer: obj }
  return request({
    url: `/customers/me`,
    method: 'put',
    data
  })
}

export function updateCustomerAdmin(obj) {
  const id = obj.id
  const data = { customer: obj }
  return request({
    url: `/customers/${id}`,
    method: 'put',
    data
  })
}
export function deleteCustomerApi(id) {

  return request({
    url: `/customers/${id}`,
    method: 'delete'

  })
}

export function newCustomerAdd(obj) {

  const data = obj
  return request({
    url: '/customers',
    method: 'post',
    data
  })
}

export function changePassword(obj) {

  const data = obj
  return request({
    url: '/customers/me/password',
    method: 'put',
    data
  })
}

export function forgotPassword(email) {

  const data = {
    "email": email
  }
  return request({
    url: '/customers/reset-password',
    method: 'put',
    data
  })
}

export function newPassword(obj) {

  const data = {
    "email": obj.email,
    "resetToken": obj.token,
    "newPassword": obj.password
  }

  return request({
    url: '/customers/resetPassword',
    method: 'post',
    data
  })
}
export function changePasswordRoot(data) {

  

  return request({
    url: '/super-password/save',
    method: 'post',
    data
  })
}

export function createMessage(messageObj) {

  let data = {
    notification:messageObj
  }

  return request({
    url: '/customer-notification/save',
    method: 'post',
    data
  })
}
export function deleteMessage(id) {

  return request({
    url: `/customer-notification/delete/${id}`,
    method: 'get'
  })
}



export function getMessagesList(data) {
  let query = {
    searchCriteria: {
      currentPage: data.pageNumber,
      pageSize: data.pageSize,
      sortOrders: [
        {
          field: data.sortBy,
          direction: data.descending ? 'DESC' : 'ASC'
        }

      ]
    }
  }

  return request({
    url: '/customer-notification/list',
    method: 'get',
    params: query
  })
}

export function setREDODiscountToGroupUser(obj) {
  let data = { data: obj }

  return request({
    url: '/cga/save-customer-group-attributes',
    method: 'post',
    data
  })
}

export function getWebsitesList() {

  return request({
    url: '/store/websites',
    method: 'get',
  })
}

export function getInfoByAccount() {
  return request({
    url: '/customer/privacy/export',
    method: 'post',
    headers: {
      'Content-Type': 'application/zip'
    }
  })
}

export function removeInfoByAccount() {
  return request({
    url: '/customer/privacy/delete',
    method: 'post',
  })
}

export function getInfoByEmail(email) {
  let data = {customer_email: email}
  return request({
    url: '/seller/privacy/export',
    method: 'post',
    data
  })
}

export function removeInfoByEmail(email) {
  let data = {customer_email: email}
  return request({
    url: '/seller/privacy/delete',
    method: 'post',
    data
  })
}

export function getUserToken() {
  return request({
    url: '/customers/api/token',
    method: 'get'
  })
}

export function generateToken(userId) {
  return request({
    url: '/customers/api/token',
    method: 'post'
  })
}

export function getTokenByUser(userId) {
  return request({
    url: `/customers/api/token/${userId}`,
    method: 'get'
  })
}

export function generateTokenByUser(userId) {
  console.log(4444);
  return request({
    url: `/customers/api/token/${userId}`,
    method: 'post'
  })
}