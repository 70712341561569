<template>
  <v-text-field
    solo
    light
    v-mask="'##'"
    class="input-center input-qty"
    append-outer-icon="mdi-plus"
    prepend-icon="mdi-minus"
    @click:append-outer.stop="increase"
    @click:prepend.stop="decrease"
    @change="$emit('input', $event)"
    :value="parseInt(value)"
    hide-details
    :class="classExtend"
    :background-color="bgcolor"
    :dark="dark"
  />
</template>
<script>
export default {
  data(){
      return{
          step:1,
          max:100,
          min:1
      }
  },
  props:['value', 'classExtend','bgcolor', 'dark'],
  methods: {
    increase() {
      
      if (isNaN(parseInt(this.value))) return this.$emit("input", this.step);
      if (this.value === this.max) return;
      
      this.$emit("input", parseInt(this.value) + this.step);
    },

    decrease() {
      
      if (isNaN(parseInt(this.value))) return this.$emit("input", this.min);
      if (this.value === this.min) return;
      this.$emit("input", parseInt(this.value) - this.step);
    }
  }
};
</script>
<style>
  .v-text-field.v-text-field--solo.min-height .v-input__control{
    min-height: 28px;
  }
  .v-text-field.v-text-field--solo.min-height .v-input__prepend-outer, .v-text-field.v-text-field--solo.min-height .v-input__append-outer{
    margin-top: 5px;
  }
  
</style>
