import _ from 'lodash'

export function findValues(obj, key){
  let findValuesDeepByKey = (obj, key, res = []) => (
    _.cloneDeepWith(obj, (v,k,o) => {
      if (k=='id' && v == key) {
        res = o
      }
       
    }) && res
  )
  return findValuesDeepByKey(obj, key)
}

export function findValuesByOptionId(obj, option_id, id){
  let findValuesDeepByKey = (obj, option_id, id, res = []) => (
    _.cloneDeepWith(obj, (v,k,o) => {
      if (k=='option_id' && v == option_id) {
      	
      	if (o.id == id) {
      		res = o
      	}
        
      }
       
    }) && res
  )
  return findValuesDeepByKey(obj, option_id, id)
}

export function filterAttributes(obj){
	let result = _.map(obj, item => { 
		return { 
			option_id: item.attribute_id,
			attribute_code: item.attribute_code,
			options:item.options
		} 
	})
	
	return result
}

export function findAttributes(obj, key, param){
	
	let attributes = _.find(obj.attributes, function(v,k){
		return k == key
	})
	if (_.isNil(attributes)) {
		return null
	}

	if (param == 'range') {
		attributes = _.toInteger(attributes) + 1
		return _.range(1, attributes)
	}
	if (param == 'value') {
		return attributes
	}
}


export function findMixAttributes(obj, key, attr){
	
	let prodAttrId = obj.attribute_set_id
	let storeAttr = _.find(attr, function(v){
		return v.key == prodAttrId
	})
	if (_.isNil(storeAttr)) {
		return null
	}
	
	let storeAttrKey = _.find(storeAttr.options, function(v){
		return v.attribute_code == key
	})


	let attributes = _.find(obj.attributes, function(v, k){
		return k == key
	})
	
	let attributesSplit = _.split(attributes, ',')
	
	let result = _.map(attributesSplit, function(value) {
	  return { value: value }
	})


	return _.intersectionBy(storeAttrKey.options, result, 'value')
	
	
	
}