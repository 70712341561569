import Cart from "../../domain/entities/cart";
import StockItem from "../../domain/entities/stock_item";
import Bundle from "../../domain/entities/bundle";
import BundleItem from "../../domain/entities/bundle_item";
import Simple from "../../domain/entities/simple";

/**
 * ToDo: Create ./data/models and remove direct references to entities
 * 
 * @class CartDto
 */
export default class CartDto {

    cartItems;
    address;
    stockId;
    allLaserhubs;
    laserhubsResponse;

    /**
     * @constructor
     * 
     * @param {*} cartItems 
     * @param {*} address 
     * @param {*} stockId 
     * @param {*} allLaserhubs 
     * @param {*} laserhubsResponse 
     */
    constructor(cartItems, address, stockId, allLaserhubs, laserhubsResponse) {
        this.cartItems = cartItems;
        this.address = address;
        this.stockId = stockId;
        this.allLaserhubs = allLaserhubs;
        this.laserhubsResponse = laserhubsResponse;
    }

    /**
     * @returns {Cart}
     */
    toCart() { 
        const hubs = this.allLaserhubs.map((laserhub) => laserhub.sourceCode);
        const stock = new Map();
        let qtys = new Map();
        let qtysToDeductBySource = new Map();
        const products = [];
    
        for (const item of this.laserhubsResponse.source_selection_items) {
            let key = item.source_code + '_' + item.sku;
            let stockItem = new StockItem(item.sku, item.source_code, item.qty_available, item.qty_to_deduct)
            stock.set(key, stockItem)

            let qty = item.qty_to_deduct;
            if (qtysToDeductBySource.has(item.source_code)) {
                qty += qtysToDeductBySource.get(item.source_code);
            } 
            qtysToDeductBySource.set(item.source_code, qty)
        }
    
        for (const cartItem of this.cartItems) {
            let product = null;
            if (cartItem.product_type == 'bundle') {
                product = new Bundle();
                for (const simple of cartItem.product_option.extension_attributes.bundle_options) {
                    let bundleAvailability = new Map();
                    for (const hub of hubs) {
                        let key = hub + '_' + simple.sku;
                        let item = stock.get(key);
                        if (item == null && item == undefined) {
                            item = new StockItem(simple.sku, hub, 0, simple.option_qty)
                        }
                        bundleAvailability.set(key, item);
                    }
                    let bundleItem = new BundleItem(simple.sku, simple.option_qty, bundleAvailability)
                    product.items.push(bundleItem)
                    let qty = 1;
                    if (qtys.has(simple.sku)) {
                        qty += qtys.get(simple.sku);
                    } 
                    qtys.set(simple.sku, qty)
                }
            } else {
                let availability = new Map();
                for (const hub of hubs) {
                    let key = hub + '_' + cartItem.sku;
                    availability.set(key, stock.get(key));
                }
                product = new Simple(cartItem.sku, cartItem.qty, availability)
            }
    
            products.push(product)
        }
    
        for (const key in products) {
            if (!(products[key] instanceof Bundle)) {
                continue;
            }
            for (const j in products[key].items) {
                products[key].items[j].qty = qtys.get(products[key].items[j].sku)
            }
        }

        const laserhubs = new Map();
        for (const hub of this.allLaserhubs) {
            laserhubs.set(hub.sourceCode, hub)
        }
        const sortedSourceCodes = new Map([...qtysToDeductBySource.entries()].sort((a, b) => b[1] - a[1]));
        var sortedLaserhubs = []
        for (let [key, value] of sortedSourceCodes) {
            if (!laserhubs.has(key)) {
                continue;
            }
            sortedLaserhubs.push(laserhubs.get(key))
        }

        return new Cart(products, this.address, this.stockId, sortedLaserhubs)
    }

}