import {
    getOrderItems
} from "@/api/orders";

/**
 * @class
 */
export default class OrderDetailsService {

    /**
     * 
     * @param {*} orderId 
     * @returns 
     */
    fetchOrderDetails(orderId) {
        return new Promise((resolve, reject) => {
            getOrderItems(orderId)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

}
