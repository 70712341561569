<template>
  <v-dialog v-model="dialog" max-width="430px" v-if="dialog">
    <v-card>
      <v-card-title class="text-h5">Add discount to this product</v-card-title>

      <v-card-text>
        <v-btn-toggle v-model="amount">
          <v-btn xs3 large color="primary" v-for="(item, index) in discounts" :key="index">{{item}}%</v-btn>
          
        </v-btn-toggle>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.prevent="removeDiscount" v-if="amount >= 0">Remove</v-btn>
        <v-btn color="primary" text @click.prevent="dialog = false">Cancel</v-btn>

        <v-btn color="primary" @click.prevent="saveDiscount">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {addDiscount} from "@/api/orders";
export default {
  name: "discountCart",
  props: ["item", "discount"],
  data() {
    return {
      dialog: false,
			amount: '',
			discounts:[10,15,25,50,100]
    };
  },
  methods: {
    saveDiscount() {
			let item = {
				discounts:[]
			}
			item.discounts.push({
          quote_item_id: this.item,
          type:'percentage',
          value: this.discounts[this.amount]
			});
			this.dialog = false;
      return new Promise((resolve, reject) => {
        
        addDiscount(item)
          .then(response => {
            const data = response.data;
						this.$store.dispatch('getCartItems')
      			this.$store.dispatch('getCartTotalSum')
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    removeDiscount() {
			let item = {
				discounts:[]
			}
			item.discounts.push({
          quote_item_id: this.item,
          type:'percentage',
          value: 0
			});
			this.dialog = false;
      return new Promise((resolve, reject) => {
        
        addDiscount(item)
          .then(response => {
            const data = response.data;
						this.$store.dispatch('getCartItems')
      			this.$store.dispatch('getCartTotalSum')
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  watch: {
    discount(val) {
      if (val > 0) {
        this.amount = this._.indexOf(this.discounts, val)
      }
      
    }
  }
};
</script>