/**
 * @class
 */
export default class LaserhubRequestDto {

    items;
    address;
    stockId;

    /**
     * @constructor
     * @param {*} items 
     * @param {*} address 
     * @param {*} stockId 
     */
    constructor(items, address, stockId) {
        this.items = items;
        this.address = address;
        this.stockId = stockId;
    }

    /**
     * @constructs LaserhubRequestDto from raw json
     * @param {*} items 
     * @param {*} address 
     * @param {*} stockId 
     * @returns 
     */
    static fromDomainRawJsons(items, address, stockId) {
        return new LaserhubRequestDto(items, address, stockId);
    }

    /**
     * @returns Laserhub Request
     */
    toLaserhabRequest() {
        const formattedItems = this.formatItemsForRequest(this.items);
        const requestRaw = {
            inventoryRequest: {
                stockId: this.stockId,
                items: formattedItems,
                extension_attributes: {
                    destination_address: {
                        country: this.address.country_id,
                        postcode: this.address.postcode,
                        street: this.address.street.length > 0 ? this.address.street[0] : '',
                        region: this.address.region_id,
                        city: this.address.city,
                    },
                },
            },
            algorithmCode: "distance"
        }
        
        return requestRaw;
    }

    /**
     * @param {*} cartItems 
     * @returns formatted items
     */
    formatItemsForRequest(cartItems) {
        var dataForAlgo = new Map();
        for (const cartItem of cartItems) {
            if (cartItem.product_type == 'bundle') {
                for (const simple of cartItem.product_option.extension_attributes.bundle_options) {
                    let qty = simple.option_qty;
                    let item = {};
                    if (dataForAlgo.has(simple.sku)) {
                        item = dataForAlgo.get(simple.sku);
                        item.qty = item.qty + qty;
                    } else {
                        item = {
                            sku: simple.sku,
                            qty: qty
                        }
                    }
                    dataForAlgo.set(simple.sku, item)
                }
            } else {
                let qty = cartItem.qty;
                let item = {};
                if (dataForAlgo.has(cartItem.sku)) {
                    item = dataForAlgo.get(cartItem.sku);
                    item.qty = item.qty + qty;
                } else {
                    item = {
                        sku: cartItem.sku,
                        qty: qty
                    }
                }
            
                dataForAlgo.set(cartItem.sku, item)
            }
        }
        
        return Array.from(dataForAlgo, ([key, value]) => value);;
    }

}