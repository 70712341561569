import { getClosestAddress } from "@/api/cart";
import LaserhubRequestDto from "../dtos/laserhub_request_dto";

/**
 * Datasource
 * @class
 */
export default class LaserhubsDatasource {

    /**
     * Raw Data
     * // ToDo: rewrite to Domain Models
     * 
     * @param items 
     * @param address 
     * @param stockId 
     */
    fetchStockData(items, address, stockId) { 
        const laserhubRequestDto = LaserhubRequestDto.fromDomainRawJsons(items, address, stockId);
        const request = laserhubRequestDto.toLaserhabRequest()
             
        return this.proccessRequest(request);
    }

    /**
     * @param body 
     */
    proccessRequest(body) {
        return new Promise((resolve, reject) => {
            getClosestAddress(body)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

}