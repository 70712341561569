import _ from 'lodash'

const getters = {
    token: state => state.user.token,
    name: state => state.user.user.firstname,
    userObject: state => state.user.user,
    userId: state => state.user.user.id,
    isTermsAgree: state => !!state.user.user.custom_attributes.find(item => item.attribute_code === "terms_and_conditions")?.value,
    drawerRight: state => state.user.drawerRight,
    status: state => state.user.status,
    setting: state => state.user.setting,
    roles: state => {
        let roles = state.user.roles
        if(state.user.extension_attributes.is_personal) roles.push('staff')
        return roles
    },
    isAdmin: state => state.user.roles.indexOf('admin') >= 0,
    isCustomer: state => state.user.roles.indexOf('customer') >= 0,
    isManager: state => state.user.roles.indexOf('manager') >= 0,
    isProfit: state => state.user.roles.indexOf('LDS') <= 0,
    isLDS: state => state.user.roles.indexOf('LDS') >= 0,
    isRCCL: state => state.user.roles.indexOf('RCCL') >= 0,
    isNCL: state => state.user.roles.indexOf('NCL') >= 0,
    isCarnival: state => state.user.roles.indexOf('Carnival') >= 0,
    isFuji: state => state.user.roles.indexOf('Fuji') >= 0,
    isMSC: state => state.user.roles.indexOf('MSC') >= 0,
    websites: state => state.user.websites,
    customers: state => state.others.customers,
    allStatuses: state => state.others.allStatuses,
    paymentStatus: state => state.user.payment,
    filteredStatuses: state => state.others.filteredStatuses,
    filtersStore: state => state.others.filtersStore,
    addRouters: state => state.permission.addRouters,
    categoryList: state => state.products.categoryList,
    productList: state => state.products.productList,
    productAttributes: state => state.products.attributes,
    cartId: state => state.cart.cartId,
    cartItems: state => state.cart.cartItems,
    fullCart: state => state.cart.fullCart,
    delayOrder: state => state.cart.delayOrder,
    cartTotalSum: state => state.cart.cartTotalSum,
    hasTryNowOrders: state => state.cart.hasTryNowOrders,
    isCartEditing: state => state.cart.isCartEditing,
    laserhubLoadingError: state => state.cart.laserhubLoadingError,
    availableLaserhubs: state => state.cart.availableLaserhubs,
    fromUpdate: state => state.cart.fromUpdate,
    searchTerm: state => state.search.searchTerm,
    bundleOptionsTitle: state => state.products.bundleOptionsTitle,
    customerGroups: state => {
        let groups = state.user.customerGroups;
        let match = _.findIndex(groups, {id: 0})
        if (match !== -1) {
            groups.splice(match, 1)
        }
        return groups;
    },
    paymentTypes: state => state.user.paymentTypes,
    userPaymentType: state => {
        let type = _.find(state.user.user.custom_attributes, {attribute_code: "payment_type"})
        if (!_.isNil(type)) {
            return type.value
        } else {
            return '';
        }
    },
    firstLogin: state => {
        let type = _.find(state.user.user.custom_attributes, {attribute_code: "need_show_settings"})
        if (!_.isNil(type)) {
            return type.value
        } else {
            return 1;
        }
    },
    cartSimpleProducts: state => {
        return _.filter(state.cart.cartItems, {product_type: "bundle"})

    },
    currencies: state => state.user.currencies,
    loader: state => state.user.loader,
    customerLogo: state => state.user.customerLogo,
    currency: state => {
        if (state.user.user) {
            let currency = _.find(state.user.user.custom_attributes, {attribute_code: "currency_type"})
            if (!_.isNil(currency)) {
                return currency.value
            } else {
                return 'USD';
            }
        } else {
            return 'USD';
        }

    },
    errorNotify: state => state.user.errorNotify,
    errorCount: state => state.user.errorCount,
    productsCostsList: state => state.user.productsCostsList,
    adminMessages: state => state.user.adminMessages,

    getReportData: state => state.report,

    notifications: state => state.others.notifications,
    allLaserhubs: state => state.user.sources,
    stockId: state => state.user.stockId,
    selectedLaserhubId: state => state.cart.selectedLaserhubId,
    isProfitWebSite: state => process.env.VUE_APP_CODE === "profit"
}

export default getters
