/**
 * @class
 */
export default class Laserhub {
    
    sourceCode;
    name;

    /**
     * @constructor
     */
    constructor(sourceCode, name) {
        this.sourceCode = sourceCode;
        this.name = name
    }

}
