import {getReadyReport} from "../../api/orders";

const report = {
  state: {
    isGenerating: false,
    reportId:0,
    file:null,
    ...JSON.parse(sessionStorage.getItem('report'))
  },

  mutations: {
    SET_GENERATION_RUN: (state,id) => {
      state.isGenerating = true
      state.reportId = id
      state.file = null
    },
    SET_GENERATION_STOP: (state) => {
      state.isGenerating = false
      state.file = null
    },
    SET_GENERATION_DONE: (state,data) => {
      state.isGenerating = false
      state.file = data
      state.reportId = 0
    },
  },

  actions: {
    runReportGeneration({commit, state, dispatch},id) {
      commit('SET_GENERATION_RUN',id)
      dispatch('checkReportGeneration')
    },
    checkReportGeneration({commit, state, dispatch},id) {
      let timerId = setInterval(() =>{
        if(!state.isGenerating)
          clearInterval(timerId)
        else
          dispatch('getReport')
      }, 5000);
    },
    stopReportGeneration({commit, state, dispatch}) {
      sessionStorage.removeItem('report');
      commit('SET_GENERATION_STOP');
    },
    async getReport ({ commit , state }) {
      sessionStorage.setItem('report', JSON.stringify(state));

      new Promise((resolve, reject) => {
        getReadyReport(state.reportId).then(response => {
          if (!response.data) {
            reject('error')
          }
          let data = response.data[0];

          if(data.status=="done"){
            commit('SET_GENERATION_DONE', data.file)
            sessionStorage.setItem('report', JSON.stringify(state));

          }else if(data.status=="error")
            commit('SET_GENERATION_DONE')
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      });
    }
  }
}

export default report
