import {getStatuses} from "@/api/orders";
import {allCustomers} from "@/api/admin.js";
import _ from 'lodash'

const others = {
    state: {
        notifications: [],
        customers: [],
        allStatuses: [],
        filteredStatuses: [],
        filtersStore: {
            website_id: false,
            filterDate: [],
            filters: {},
        }
    },

    mutations: {
        SET_CUSTOMERS_LIST: (state, list) => {
            state.customers = list
        },
        SET_STATUSES_LIST: (state, list) => {
            state.allStatuses = list
        },
        SET_FILTERED_STATUSES_LIST: (state, list) => {
            let filterItems = [
                "pending",
                "with_3D_artist",
                "ready_for_laser",
                "now_burning",
                "shipped",
                "received",
                "picked_up_by_customer",
                "collected_by_customer"
            ];
            state.filteredStatuses = _.map(filterItems, function (item) {
                return _.find(list, {value: item});
            });
        },
        SET_NOTIFICATION: (state, item) => {
            _.remove(state.notifications, function (obj) {
                return obj.isShow
            });

            state.notifications.push(item)
        },
        SET_FILTER_WEBSITE: (state, id) => {
            state.filtersStore.website_id = id
        },
        SET_FILTER_DATE: (state, date) => {
            state.filtersStore.filterDate = date
        },
        SET_FILTERS: (state, obj) => {
            state.filtersStore.filters = obj
        },
    },

    actions: {
        pushSuccessNotify({commit}, obj) {
            commit('SET_NOTIFICATION', {
                isShow: true,
                msg: obj.msg
            })
        },
        pushErrorNotify({ commit }, obj) {
            const msg = obj.msg || obj.message || obj.error || 'Something went wrong';
            commit('SET_NOTIFICATION', {
                isShow: true,
                msg
            });
        },
        getCustomers({commit}) {
            return new Promise((resolve, reject) => {
                allCustomers()
                    .then(response => {
                        if (!response.data) {
                            reject("error");
                        }
                        let itemFilteredByStore = _.remove(response.data.items, item => {
                            if (process.env.VUE_APP_TYPE == 'profit'){
                                return true;
                            } else {
                                return item.store_id == process.env.VUE_APP_STORE_ID
                            }
                        });
                        let items = _.map(itemFilteredByStore, item => {
                            return {
                                label: item.email,
                                value: item.email,
                                id: item.id
                            };
                        });

                        commit('SET_CUSTOMERS_LIST', items)
                        resolve(items);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getStatuses({commit}) {
            return new Promise((resolve, reject) => {
                getStatuses()
                    .then(response => {
                        if (!response.data) {
                            reject("error");
                        }
                        let data = response.data;
                        data.unshift({
                            value: "",
                            label: "All"
                        });
                        commit('SET_STATUSES_LIST', data)
                        commit('SET_FILTERED_STATUSES_LIST', data)
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        setFilterWebId({commit}, value) {
            commit('SET_FILTER_WEBSITE', value);
        },
        setFilterDate({commit}, value) {
            commit('SET_FILTER_DATE', value);
        },
        setFilters({commit}, obj) {
            commit('SET_FILTERS', obj);
        },
    }
}

export default others
