import LaserhubsDatasource from "../data/datasources/laserhubs_datasource";
import LaserhubsRepositoryImpl from "../data/repositories/laserhubs_repository_impl"
import AvailableLaserhubsSelector from "../../../services/laserhub/available_laserhubs_selector";
import RetriveAvailableLaserhubs from "../domain/usecases/retrieve_available_laserhubs";
import OrderDetailsService from "../../../services/laserhub/order_details_service";

/**
 * @class Factory
 */
export default class LaserhubClassesFactory {

    /**
     * @returns {RetriveAvailableLaserhubs}
     */
    static getRetrieveAvailableLaserhubsUsecase() {
        const laserhubDatasource = new LaserhubsDatasource()
        const laserhubRepository = new LaserhubsRepositoryImpl(laserhubDatasource);
        const availableLaserhubsSelector = new AvailableLaserhubsSelector()

        return new RetriveAvailableLaserhubs(laserhubRepository, availableLaserhubsSelector);
    }

    /**
     * @returns {OrderDetailsService}
     */
    static getOrderDetailsService() {
        return new OrderDetailsService();
    }

}