const search = {
  state: {
    searchTerm:''
  },

  mutations: {
    'SET_SEARCH' (state, text) {
      state.searchTerm = text
    },
  },

  actions: {
    setSearch: ({commit}, text) => {
        commit('SET_SEARCH', text)
    },
  },

  getters: {
    getSearchTerm: state => state.searchTerm
  }
}

export default search