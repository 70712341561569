import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

/* Layout */
import Layout from './views/layout/Layout'
import Login from './views/Login'


export const constantRouterMap = [
    {path: '/login', name: 'login', component: Login},
    {path: '/login/admin', name: 'loginAdmin', component: Login},
    {path: '/forgot-pass', name: 'forgotPass', component: () => import('./views/ForgotPass')},
    {path: '/new-pass', name: 'newPass', component: () => import('./views/NewPassword')},
    {path: '/', redirect: 'orders', component: Layout}
]

export default new Router({
    mode: 'history',
    routes: constantRouterMap,
})

export const asyncRouterMap = [
    {
        path: '/',
        redirect: 'orders',
        component: Layout,
        meta: {
            roles: ['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC'],
            pageTitle: 'Catalog'
        },

        children: [
            {
                path: 'carts',
                name: 'carts',
                component: () => import('./views/carts/index'),
                meta: {
                    roles: ['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC'],
                    pageTitle: 'Carts'
                },
            },
            {
                path: 'orders',
                name: 'orders',
                component: () => import('./views/orders/Orders'),
                meta: {
                    roles: ['admin', 'manager', 'customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC'],
                    pageTitle: 'Orders'
                },
                beforeEnter:(to, from, next) => {
                    const queryParams = to.query;
                    if (queryParams && queryParams.query) {
                        store.dispatch('setSearch', queryParams.query);
                    }
                    next();
                }
            },
            {
                path: 'order/:orderId',
                name: 'order',
                component: () => import('./views/orders/Order'),
                meta: {
                    pageTitle: 'Orders'
                },
            },
            {
                path: 'catalog',
                name: 'catalogList',
                component: () => import('./views/products/list'),
                meta: {
                    roles: ['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC'],
                    pageTitle: 'Catalog'
                },
                children: [
                    {
                        path: ':id',
                        name: 'productList',
                        component: () => import('./views/products/list'),
                        meta: {
                            roles: ['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC']
                        }
                    }
                ],
                beforeEnter: (to, from, next) => {
                    if (store.getters.categoryList.length == 0) {
                        store.dispatch('products/mainLoading')
                    }
                    next()
                }
            },
            {
                path: 'product/:sku',
                name: 'productItem',
                component: () => import('./views/products/item'),
                meta: {
                    roles: ['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC'],
                    pageTitle: 'Product'
                },
                children: [
                    {
                        path: 'edit/:cartItemId',
                        name: 'productItemEdit',
                        component: () => import('./views/products/item'),
                        meta: {
                            roles: ['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC'],
                            pageTitle: 'Product Edit'
                        },
                    },
                ]
            },
            {
                path: 'product/:sku/hash/:hash',
                name: 'productItem',
                component: () => import('./views/products/item'),
                meta: {
                    roles: ['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC'],
                    pageTitle: 'Product'
                },
                children: [
                    {
                        path: 'edit/:cartItemId',
                        name: 'productItemEditWithHash',
                        component: () => import('./views/products/item'),
                        meta: {
                            roles: ['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC'],
                            pageTitle: 'Product Edit'
                        },
                    },
                ]
            },
            {
                path: 'cart/:id',
                name: 'cartItem',
                component: () => import('./views/cart/item'),
                meta: {
                    roles: ['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC']
                },
                beforeEnter: (to, from, next) => {
                    to.meta.pageTitle = `Order #${to.params.id}`;
                    next()
                }
            },
            {
                path: 'payments',
                name: 'paymentsList',
                component: () => import('./views/payments/index'),
                meta: {
                    roles: ['customer', 'admin', 'manager','Fuji'],
                    pageTitle: 'Payments'
                },
            },
            {
                path: 'settings',
                name: 'settings',
                component: () => import('./views/manager/settings'),
                meta: {
                    roles: ['admin', 'manager', 'customer','Fuji'],
                    notAllowedRoles: ['staff'],
                    pageTitle: 'Settings'
                },
            },
            {
                path: 'admin/settings',
                name: 'adminSettings',
                component: () => import('./views/admin/settings'),
                meta: {
                    roles: ['admin'],
                    pageTitle: 'Settings'
                },
            },
            {
                path: 'edit/products',
                name: 'productsEdit',
                component: () => import('./views/products/edit'),
                meta: {
                    roles: ['customer','Fuji'],
                    pageTitle: 'Edit Products'
                },
            },
            {
                path: 'edit/taxes',
                name: 'taxesEdit',
                component: () => import('./views/manager/taxes'),
                meta: {
                    roles: ['customer','Fuji'],
                    pageTitle: 'Edit Taxes'
                },
            },
            {
                path: 'edit/email',
                name: 'emailEdit',
                component: () => import('./views/manager/email'),
                meta: {
                    roles: ['customer','Fuji'],
                    pageTitle: 'Edit E-mails'
                },
            },
            {
                path: 'edit/staff',
                name: 'staffEdit',
                component: () => import('./views/manager/staffUser'),
                meta: {
                    roles: ['customer','Fuji'],
                    pageTitle: 'Edit Staff Users'
                },
            },
            {
                path: 'customers',
                name: 'customers',
                component: () => import('./views/admin/customers'),
                meta: {
                    roles: ['admin'],
                    pageTitle: 'Customers'
                }
            },
            {
                path: 'groups',
                name: 'groups',
                component: () => import('./views/admin/groups'),
                meta: {
                    roles: ['admin'],
                    pageTitle: 'Groups'
                }
            },
            {
                path: 'group/:groupId',
                name: 'group',
                component: () => import('./views/admin/group-details'),
                meta: {
                    roles: ['admin'],
                    pageTitle: 'Group'
                }
            },
            {
                path: 'messages',
                name: 'messages',
                component: () => import('./views/admin/messages'),
                meta: {
                    roles: ['admin'],
                    pageTitle: 'Messages'
                }
            },
            {
                path: 'customers/:id',
                name: 'customerDetail',
                component: () => import('./views/manager/settings'),
                meta: {
                    roles: ['admin']
                },
            },
            {
                path: 'customer/new',
                name: 'newCustomer',
                component: () => import('./views/manager/settings'),
                meta: {
                    roles: ['admin'],
                    pageTitle: 'New Customer'
                }
            },
            {
                path: 'products',
                name: 'products',
                component: () => import('./views/admin/productList'),
                meta: {
                    roles: ['admin'],
                    pageTitle: 'Products'
                }
            },
            {
                path: 'try-now',
                name: 'tryNow',
                component: () => import('./views/trynow/index'),
                meta: {
                    pageTitle: 'Try NOW'
                }
            },
            {
                path: 'product/:sku/edit',
                name: 'productEdit',
                component: () => import('./views/admin/productEdit'),
                meta: {
                    roles: ['admin'],
                    pageTitle: 'Product Edit'
                }
            },
            {
                path: 'product/new/:type',
                name: 'newProduct',
                component: () => import('./views/admin/productEdit'),
                meta: {
                    roles: ['admin'],
                    pageTitle: 'New Product'
                }
            },
            {
                path: 'statistics',
                name: 'statistics',
                component: () => import('./views/statistics/index'),
                meta: {
                    roles: ['admin', 'customer', 'manager','Fuji'],
                    pageTitle: 'Statistic'
                },
            },
        ]
    }
]
