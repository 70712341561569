<template>
  <section class="px-2 py-2 item-section">
    <v-layout align-start justify-space-between wrap class="mt-2">
      <v-flex xs1 class="text-left">
        <v-btn class="pa-0" icon light x-small @click.prevent="deleteItem">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs8>
        <div class="text-h6">{{ item.name }}</div>
      </v-flex>
      <v-flex xs3 class="text-right">
        <v-btn class="pa-1" v-if="isEdit && item.product_type === 'bundle'" text color="white" small @click.prevent="editProduct">
              <v-icon color="red" class="mr-1">mdi-pencil</v-icon>
              Edit
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout align-center justify-space-between>
      <v-flex xs4>
        <span
          class="subheading font-weight-medium grey--text text--lighten-1"
        >{{ item.price | currency }}</span>
      </v-flex>
      <template v-if="!isEdit">
        <v-flex>
          <v-btn text light icon small @click.prevent="removeItemQty">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs4>
          <v-text-field
              :value="item.qty"
              height="28"
              text
              background-color="black"
              class="input-center"
              @change="changeQty"
          ></v-text-field>
        </v-flex>
        <v-flex>
          <v-btn text light icon small @click.prevent="addItemQty">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-flex>
      </template>
      <template v-else>
        <v-flex>
          Qty: {{item.qty}}
        </v-flex>
      </template>
      <v-flex
        xs4
        class="text-right text-h6 font-weight-medium"
      >{{ item.price * item.qty | currency}}</v-flex>
    </v-layout>
    <div v-if="item.product_type == 'bundle'">
    <v-flex
      
      xs12
      v-for="(option,index) in item.product_option.extension_attributes.bundle_options"
      :key="index"
    >
      <v-layout align-center justify-space-between class="mb-2">
        <v-flex xs1 class="text-left">
          <v-btn v-if="!option.required" class="pa-0" icon light x-small @click.prevent="removeBundleOption(option, index)">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
          <div v-else>
          </div>
        </v-flex>
        <v-flex xs3>
          <span class="text-caption grey--text text--lighten-3">{{ option.product_name }}</span>
          <div v-if="option.option_title == 'face'" class="text-caption nowrap-text small grey--text text--lighten-3">
            <v-tooltip v-if="item.extension_attributes.flat_background" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="title">Its free!</span>
              </template>
              <span>{{item.extension_attributes.laser_engraved_text}}</span>
            </v-tooltip>
          </div>
          <div v-if="option.option_title == 'laser_engraved_text'" class="text-caption nowrap-text small grey--text text--lighten-3">
            <v-tooltip v-if="item.extension_attributes.laser_engraved_text" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="title" v-bind="attrs" v-on="on">{{item.extension_attributes.laser_engraved_text}}</span>
              </template>
              <span>{{item.extension_attributes.laser_engraved_text}}</span>
            </v-tooltip>
          </div>
          <div v-if="option.option_title == 'customer_text'" class="text-caption nowrap-text small grey--text text--lighten-3">
            <v-tooltip v-if="item.extension_attributes.customer_text" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="title" v-bind="attrs" v-on="on"> - {{item.extension_attributes.customer_text}}</span>
                  </template>
              <span>{{item.extension_attributes.customer_text}}</span>
            </v-tooltip>
            <v-tooltip v-if="item.extension_attributes.customer_text2" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="title" v-bind="attrs" v-on="on"> - {{item.extension_attributes.customer_text2}}</span>
              </template>
              <span>{{item.extension_attributes.customer_text2}}</span>
            </v-tooltip>
            <v-tooltip v-if="item.extension_attributes.customer_text3" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="title" v-bind="attrs" v-on="on"> - {{item.extension_attributes.customer_text3}}</span>
              </template>
              <span>{{item.extension_attributes.customer_text3}}</span>
            </v-tooltip>
          </div>
        </v-flex>
        <v-flex xs5>
          <input-number
            :class-extend="'min-height white--text'"
            :bgcolor="'black'"
            :dark="true"
            v-model="option.option_qty"
            @input="updateItem()"
          />
        </v-flex>
        <v-flex xs3 class="text-right">
          <button
            class="grey--text text--lighten-1"
            @click.prevent="addDiscount(option.item_id, _.toInteger(option.discount_percent))"
          >
            <v-chip
              color="primary"
              text-color="white"
              v-if="option.discount_percent > 0"
              small
              class="smaller-chip"
            >- {{option.discount_percent | integer}} %</v-chip>
            {{ option.option_qty * option.product_price | currency}}
          </button>
        </v-flex>
      </v-layout>
    </v-flex>
    </div>

    <discount-cart ref="discountPopup" :item="itemForDiscount" :discount="itemDiscount"/>
    <v-container 
              v-if="item.extension_attributes.customer_photo" 
              class="grey darken-1 rounded-lg"
    >
      <v-row>
        <span class="mr-auto px-3 py-3">Photos</span>
        <v-btn
              @click="showPhotos()"
              small
              fab
              color="black"
              class="mx-3 my-3"
        >
          <v-icon 
                v-if="itemPhotos"
                color="red"
                large
          >
            mdi-arrow-up-bold-box-outline
          </v-icon>
          <v-icon 
                v-else
                color="red"
                large
          > 
            mdi-arrow-down-bold-box-outline
          </v-icon>
        </v-btn>    
      </v-row>
      <v-row v-if="itemPhotos" class="d-flex justify-center"
      >
        <v-card  
              v-for="(photo, index) in item.extension_attributes.customer_photo"
              :key="index"
              text 
              tile 
              class="pa-2 mx-2 d-flex align-center"
        >
          <v-img
                :src="photo"
                max-height="100"
                max-width="130"
                contain
                cover
                class="grey darken-3"
          ></v-img>
        </v-card>
      </v-row>
    </v-container>
    <v-divider light class="mt-2"></v-divider>
  </section>
</template>

<script>
import _ from "lodash";
import discountCart from "@/components/cart/discountCart";
import inputNumber from "@/components/base/inputNumber";
export default {
  name: "item",
  props: {
    item: {},
    isEdit: {}
  },
  components: {
    discountCart,
    inputNumber
  },
  data() {
    return {
      itemForDiscount: "",
      itemDiscount: "",
      itemPhotos: false,
    };
  },
  filters: {
    integer: function(value) {
      return _.toInteger(value);
    }
  },
  methods: {
    editProduct(){
      this.$router.push({ name: "productItemEdit", params: { sku: this.item.extension_attributes.main_sku, cartItemId: this.item.item_id } });
    },
    changeQty(value){
      this.item.qty = value;
      this.updateItem();
    },
    addDiscount(id, amount) {
      this.$refs.discountPopup.dialog = true;
      this.$refs.discountPopup.amount = '';
      this.itemForDiscount = id;
      this.itemDiscount = _.toInteger(amount);
    },

    addItemQty() {
      this.item.qty += 1;
      this.updateItem();
    },
    removeItemQty() {
      this.item.qty > 1 ? (this.item.qty -= 1) : 1;
      this.updateItem();
    },
    deleteItem() {
      this.$store.dispatch('setLoader', true)
      this.$store
        .dispatch("updateCartItem", { item: this.item, type: "delete" })
        .then(() => {
          this.$store.dispatch('setLoader', false)
        })
        .catch(error => {
          this.$store.dispatch('setLoader', false)
          console.log(error);
        });
    }, 
    updateItem() {
      this.$store.dispatch('setLoader', true)
      this.$store
        .dispatch("updateCartItem", { item: this.item, type: "put" })
        .then(() => {
          this.$store.dispatch('setLoader', false)
        })
        .catch(error => {
          this.$store.dispatch('setLoader', false)
          console.log(error);
        });
    },
    removeBundleOption(option) {
      if(option.option_title && option.option_title === 'customer_text') {
        this.item.extension_attributes.customer_text = "";
        this.item.extension_attributes.customer_text2 = "";
        this.item.extension_attributes.customer_text3 = "";
      }
      this._.remove(this.item.product_option.extension_attributes.bundle_options, { item_id: option.item_id });
      this.updateItem();
    },
    showPhotos() {
      this.itemPhotos = !this.itemPhotos;
    }
  }
};
</script>

<style>
.item-section{
  width: 100%;
}
.smaller-chip {
	height: 16px;
    font-size: 11px;
    margin: 0;
}
.smaller-chip .v-chip__content {
	padding: 0 5px;
}
.v-application .text-caption.small .title{
  font-size: .6rem!important;
  line-height: 1rem;
}
.nowrap-text{
  display: table;
  table-layout: fixed;
  width: 100%;
}
.nowrap-text .title{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: block;
}
</style>
