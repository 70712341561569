import { getOrderItems } from "@/api/orders";
import { getClosestAddress } from "@/api/cart";
import Cart from "../../features/laserhub/domain/entities/cart";
import Laserhub from "../../features/laserhub/domain/entities/laserhub";
import CantShipTogetherError from "../../core/errors/laserhub/cant_ship_together_error";
import NotInStockError from "../../core/errors/laserhub/not_in_stock_error";

/**
 * @class
 */
export default class AvailableLaserhubsSelector {

    /**
     * 
     * @param {Cart} cart 
     * @returns Laserhub[]
     */
    retrieveAvailableLaserhubs(cart) {
        const products = cart.products;
        const allLaserhubs = cart.sortedLaserhubs;
        let availableLaserhubs = [];
        let partlyAvailability = false;
        for (let hub of allLaserhubs) {
            let i = 0;
            for (i = 0; i < products.length; i++) {
                let bundle = products[i];
                if (!bundle.isAvailable(hub.sourceCode)) {
                    break;
                }
            }
            if (i == products.length) {
                availableLaserhubs.push(hub)
            } else if (i > 0) {
                partlyAvailability = true;
                break;
            }
        }
    
        if (availableLaserhubs.length < 1) {
            if (partlyAvailability) {
                throw new CantShipTogetherError();
            }
            throw new NotInStockError();
        }
    
        return availableLaserhubs;
    }

}
