<template>
  <section class="wrapper-secion-custom">
    <v-container fluid class="wrapper-secion-custom d-flex flex-column justify-space-between">
      <div class="progress-absolute" v-if="loader">
        <v-progress-circular :size="70" :width="7" indeterminate color="primary"></v-progress-circular>
      </div>

      <v-flex xs12 class="pt-2 pb-1 px-2">
        <v-layout align-space-around justify-space-around wrap>
          <v-flex xs10>
            <div v-if="isCartEditing" class="text-h6 h7-modified-size" style="font-size: 1rem !important;">YOU ARE EDITING</div>
            <div class="text-h5">Order list # {{ cartId }}
              <span v-if="fullCart.extension_attributes && fullCart.extension_attributes.is_redo == 1" class="red--text">(REDO)</span>
            </div>
            <div class="text-caption grey--text text--darken-1">{{ cartItems.updated_at }}</div>
          </v-flex>
          <v-flex xs2>
            <v-btn text light icon @click.prevent="toggleCart" class="my-0">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="black">
        <v-text-field
          append-icon="mdi-magnify"
          placeholder="Type something"
          background-color="black"
          solo
          hide-details
          color="white"
          v-model="searchText"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 fill-height d-flex>
        <v-layout id="scroll-cart" class="overflow-y-auto scroll-cart" grid-list-xl>
          <v-layout column align-start justify-start style="height:100%">
            <item v-for="(item,key) in searchItems" :item="item" :key="key" :is-edit="(fullCart.extension_attributes && fullCart.extension_attributes.is_redo)? true : false"></item>
          </v-layout>
        </v-layout>
      </v-flex>


      
      <v-flex xs12>
        <v-layout align-space-around justify-space-around column fill-height>
          <v-flex xs3 class="black pa-2">
            <v-layout align-space-between justify-space-between>
              <v-flex xs6>
                <div class="text-body-1">Subtotal :</div>
              </v-flex>
              <v-flex xs6>
                <div
                        class="text-body-1 font-weight-medium text-right"
                >{{ cartTotalSum.subtotal | currency}}</div>
              </v-flex>
            </v-layout>
            <v-layout
                    align-space-between
                    justify-space-between
                    v-if="cartTotalSum.base_shipping_amount > 0"
            >
              <v-flex xs6>
                <div class="text-body-1">Shipping :</div>
              </v-flex>
              <v-flex xs6>
                <div
                        class="text-body-1 font-weight-medium text-right"
                >{{ cartTotalSum.base_shipping_amount | currency}}</div>
              </v-flex>
            </v-layout>
            <v-layout
                    align-space-between
                    justify-space-between

            >
              <v-flex xs6>
                <div class="text-body-1">Taxes :</div>
              </v-flex>
              <v-flex xs6>
                <div
                        v-if="cartTotalSum.base_tax_amount > 0"
                        class="text-body-1 font-weight-medium text-right"
                >{{ cartTotalSum.base_tax_amount | currency}}</div>
              </v-flex>
            </v-layout>
            <v-layout
                    align-space-between
                    justify-space-between

            >
              <v-flex xs6>
                <div class="text-body-1">Discount :</div>
              </v-flex>
              <v-flex xs6>
                <div
                        v-if="cartTotalSum.discount_amount > 0"
                        class="text-body-1 font-weight-medium text-right"
                >- {{ cartTotalSum.discount_amount | currency}}</div>
                <div
                        v-else
                        class="text-body-1 font-weight-medium text-right"
                >{{ 0 | currency}}</div>
              </v-flex>
            </v-layout>
            <v-layout align-space-between justify-space-between class="pt-2">
              <v-flex xs6>
                <div class="text-h5">Total :</div>
              </v-flex>
              <v-flex xs6>
                <div
                        v-if="cartTotalSum.base_tax_amount > 0"
                        class="text-h5 font-weight-medium text-right primary--text"
                >{{ cartTotalSum.base_grand_total | currency}}</div>
                <div
                        v-else
                        class="text-h5 font-weight-medium text-right primary--text"
                >{{ cartTotalSum.grand_total | currency}}</div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout wrap justify-space-between class="ma-1">
              <v-btn icon color="primary" large @click.prevent="addCartDiscount">
                <v-icon>mdi-label-percent</v-icon>
              </v-btn>
              <v-btn
                      v-if="$route.name == 'cartItem'"
                      color="grey darken-1"
                      large
                      @click.prevent="delayOrder"
                      class="flex xs8"
              >DELAY</v-btn>
              <v-btn
                      v-if="cartId && $route.name != 'cartItem'"
                      color="primary"
                      large
                      class="flex xs8"
                      :to="{ name: 'cartItem', params: { id: cartId }}"
              >
                GO
                <v-icon class="ml-3">mdi-arrow-right</v-icon>
              </v-btn>

            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      
    </v-container>
    <discount-cart-amount ref="discountPopupAmount"/>
  </section>
</template>

<script>
import discountCartAmount from "@/components/cart/discountCartAmount"
import item from "./item";
import { mapGetters } from "vuex";

export default {
  name: "microcart",
  data() {
    return {
      searchText: "",
      searchItems: []
    };
  },
  components: {
    item,
    discountCartAmount
  },
  methods: {
    toggleCart() {
      this.$store.dispatch("drawerRightToggle", false);
    },
    delayOrder() {
      this.$store.dispatch("delayOrder", true)
    },
    addCartDiscount() {
      this.$refs.discountPopupAmount.dialog = true;
    }
  },
  computed: {
    ...mapGetters(["cartId", "cartTotalSum", "loader", "fullCart", "isCartEditing"]),
    cartItems: {
      get() {
        this.searchItems = this.$store.getters.cartItems;
        return this.$store.getters.cartItems;
      },
      set(newValue) {
        this.searchItems = newValue;
      }
    }
  },
  mounted() {
    this.$store.dispatch("getCartItems")
  },
  watch: {
    searchText(val) {
      const self = this;
      let text = this._.toLower(val);
      let items = this._.filter(this.cartItems, function(item) {
        let name = self._.toLower(item.name);
        return self._.includes(name, text);
      });
      this.cartItems = items;
    }
  }
};
</script>

<style scoped>
.wrapper-secion-custom {
  height: 100%;
}
.scroll-y.scroll-cart {
  height: calc(100vh - 350px);
}

@media screen and (max-width: 1267px) {
  .scroll-y.scroll-cart {
    height: calc(100vh - 283px);
  }
}
.flex.xs12 {
  flex-basis: unset !important;
}
.h7-modified-size {
  font-size: 1rem !important;
}
</style>
