import router from './router'
import store from './store'
import {getToken, removeToken, getAdminToken} from '@/utils/auth'

function hasPermission(roles, permissionRoles) {
    if (roles.indexOf('admin') >= 0) return true
    if (roles.indexOf('manager') >= 0) return true
    if (!permissionRoles) return true
    return roles.some(role => permissionRoles.indexOf(role) >= 0)
}

function isBlocked(roles=[], blockedRoles=[]) {
    return roles.some(role => blockedRoles.indexOf(role) >= 0)
}

const whiteList = ['login', 'loginAdmin', 'forgotPass', 'newPass', 'formUpload', 'list', 'replace', 'shippingForm']

router.beforeEach((to, from, next) => {

    if (getToken()) {
        if (to.path === '/login') {
            next({path: '/'})
        }else {
            if (store.getters.roles.length === 0) {

                store.dispatch('GetUserInfo').then(res => {
                    let roles = []
                    let role = null; 

                    if (process.env.VUE_APP_TYPE == 'profit') {
                        role = 'customer'
                    } else if (process.env.VUE_APP_TYPE == 'LDS') {
                        role = 'LDS'
                    } else if (process.env.VUE_APP_TYPE == 'RCCL') {
                        role = 'RCCL'
                    } else if (process.env.VUE_APP_TYPE == 'NCL') {
                        role = 'NCL'
                    } else if (process.env.VUE_APP_TYPE == 'Carnival') {
                        role = 'Carnival'
                    } else if (process.env.VUE_APP_TYPE == 'Fuji') {
                        role = 'Fuji'
                    } else if (process.env.VUE_APP_TYPE == "MSC") {
                        role = 'MSC'
                    }
                    roles.push(role)

                    store.commit('SET_ROLES', role)
                    store.commit('SET_EXTENSION_ATTRIBUTES', res.extension_attributes)
                    store.dispatch('products/mainLoading')
                    if (!store.getters.allStatuses.length) {
                        store.dispatch('getStatuses')
                    }
                    if (!store.getters.adminMessages.length) {
                        store.dispatch('checkMessages');
                    }
                    store.dispatch('GenerateRoutes', {roles}).then(() => {
                        router.addRoutes(store.getters.addRouters)
                        next({...to, replace: true})
                    })
                }).catch((err) => {
                    store.dispatch('FedLogOut').then(() => {
                        next({path: '/'})
                    })
                })
            } else {
                if (hasPermission(store.getters.roles, to.meta.roles) && !isBlocked(store.getters.roles, to.meta.notAllowedRoles)) {
                    next()
                } else {
                    next({path: '/', replace: true, query: {noGoBack: true}})
                }
            }
        }
    } else if (getAdminToken()) {
        if (to.path === '/login') {
            next({path: '/'})
        } else {
            if (store.getters.roles.length === 0) {
                store.dispatch('GetPaymentTypes')
                store.dispatch('fetchInventoryRelatedInfo')
                // store.dispatch('GetUserInfo')

                let roles = []
                let role = null;

                if (process.env.VUE_APP_TYPE == 'LDS' || process.env.VUE_APP_TYPE == 'RCCL' || process.env.VUE_APP_TYPE == 'NCL' || process.env.VUE_APP_TYPE == 'Carnival' || process.env.VUE_APP_TYPE == 'Fuji' || process.env.VUE_APP_TYPE == 'MSC') {
                    role = 'manager'
                } else {
                    role = 'admin'
                }
                roles.push(role)
                store.commit('SET_ROLES', role)

                if (!store.getters.websites.length) {
                    store.dispatch('getWebsites')
                }
                if (!store.getters.customers.length) {
                    store.dispatch('getCustomers')
                }
                if (!store.getters.allStatuses.length) {
                    store.dispatch('getStatuses')
                }
                if (!store.getters.customerGroups.length) {
                    store.dispatch('getCustomerGroup')
                }
                store.dispatch('checkCostIsCorrect')
                store.dispatch('GenerateRoutes', {roles}).then(() => {
                    router.addRoutes(store.getters.addRouters)
                    next({...to, replace: true})
                })
            } else {
                if (hasPermission(store.getters.roles, to.meta.roles)) {
                    next()
                } else {
                    next({path: '/401', replace: true, query: {noGoBack: true}})
                }
            }
        }
    } else {
        if (whiteList.indexOf(to.name) !== -1) {
            next()
        } else {
            removeToken()
            next('/login')
        }
    }
    if (to.name !== 'settings' && store.getters.userObject && store.getters.firstLogin == 1 && store.getters.roles.indexOf("customer") >= 0) {
        next({path: '/settings', replace: true});
    }
})
