<template>
    <v-list two-line>
        <div class="text-center py-1" v-permission="['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC']">
            <button @click.prevent="newOrder">
                <v-icon medium>mdi-file</v-icon>
                <div class="text-caption">New Order</div>
            </button>
            <v-divider class="grey--text divider-show"></v-divider>
        </div>

        <v-list-item active-class="custom-active-class" :to="{ name: 'catalogList'}" v-permission="['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC']">
            <v-list-item-action>
                <v-icon medium>mdi-view-list</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Catalog
            </div>
            <v-list-item-content>
                <v-list-item-title>Catalog</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="custom-active-class" :to="{ name: 'orders'}" v-permission="['admin','customer', 'LDS', 'RCCL', 'NCL', 'manager', 'Carnival','Fuji', 'MSC']">
            <v-list-item-action>
                <v-icon medium>mdi-basket</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Orders
            </div>
            <v-list-item-content>
                <v-list-item-title>Orders</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="custom-active-class" :to="{ name: 'tryNow'}" v-permission="['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC']">
          <v-list-item-action>
            <v-icon medium>mdi-cellphone-arrow-down</v-icon>
          </v-list-item-action>
          <div class="hidden-sm-and-up mobile-title text-caption">
            <v-spacer></v-spacer>
            Try NOW
          </div>
          <v-list-item-content>
            <v-list-item-title>Try NOW</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="custom-active-class" :to="{ name: 'carts'}" v-permission="['customer', 'LDS', 'RCCL', 'NCL', 'Carnival','Fuji', 'MSC']">
            <v-list-item-action>
                <v-icon medium>mdi-cart</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Carts
            </div>
            <v-list-item-content>
                <v-list-item-title>Carts</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="custom-active-class" :to="{ name: 'paymentsList'}" v-permission="['customer','Fuji']"
                     v-if="userObject && userObject.extension_attributes.is_personal == false">
            <v-list-item-action>
                <v-icon medium>mdi-credit-card</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Payments
            </div>
            <v-list-item-content>
                <v-list-item-title>Payments</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="custom-active-class" :to="{ name: 'paymentsList'}" v-permission="['admin', 'manager']">
            <v-list-item-action>
                <v-icon medium>mdi-credit-card</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Payments
            </div>
            <v-list-item-content>
                <v-list-item-title>Payments</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
                active-class="custom-active-class"
                v-if="roles.indexOf('admin') >= 0"
                :to="{ name: 'customers'}"
                v-permission="['admin']"
        >
            <v-list-item-action>
                <v-icon medium>mdi-account-supervisor-circle</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Customers
            </div>
            <v-list-item-content>
                <v-list-item-title>Customers</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
                active-class="custom-active-class"
                v-if="roles.indexOf('admin') >= 0"
                :to="{ name: 'groups'}"
                v-permission="['admin']"
        >
            <v-list-item-action>
                <v-icon medium>mdi-account-supervisor</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Groups
            </div>
            <v-list-item-content>
                <v-list-item-title>Groups</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
                active-class="custom-active-class"
                v-if="roles.indexOf('admin') >= 0"
                :to="{ name: 'messages'}"
                v-permission="['admin']"
        >
            <v-list-item-action>
                <v-icon medium>mdi-message-text</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Messages
            </div>
            <v-list-item-content>
                <v-list-item-title>Messages</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
                active-class="custom-active-class"
                v-if="roles.indexOf('admin') >= 0"
                :to="{ name: 'products'}"
                v-permission="['admin']"
        >
            <v-list-item-action>
                <v-icon medium>mdi-dns</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Products
            </div>
            <v-list-item-content>
                <v-list-item-title>Products</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="custom-active-class" :to="{ name: 'statistics'}" v-permission="['admin', 'customer', 'manager','Fuji']"
                     v-if="!userObject || userObject && userObject.extension_attributes.is_personal == false">
            <v-list-item-action>
                <v-icon medium>mdi-chart-line-variant</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Statistics
            </div>
            <v-list-item-content>
                <v-list-item-title>Statistics</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="custom-active-class" :to="{ name: 'settings'}" v-permission="['customer','Fuji']"
                     v-if="userObject && userObject.extension_attributes.is_personal == false">
            <v-list-item-action>
                <v-icon medium>mdi-cog</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Settings
            </div>
            <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="custom-active-class" :to="{ name: 'adminSettings'}" v-permission="['admin']">
            <v-list-item-action>
                <v-icon medium>mdi-cog</v-icon>
            </v-list-item-action>
            <div class="hidden-sm-and-up mobile-title text-caption">
                <v-spacer></v-spacer>
                Settings
            </div>
            <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
    import {mapGetters} from "vuex";
    import permission from "@/directive/permission/index.js";
    import {quoteStatusSet} from "@/api/products";

    export default {
        directives: {permission},
        methods: {
            newOrder() {
                if (!this.cartId) {
                    this.$store.dispatch("createCart");
                } else if (this.cartId) {
                    let id = this.cartId;
                    let status = 0;
                    if (this.cartItems.length > 0) {
                        return new Promise((resolve, reject) => {
                            quoteStatusSet(id, status)
                                .then(response => {
                                    const data = response.data;
                                    if (data) {
                                        this.$store.dispatch("clearCreateCart");
                                    }
                                })
                                .catch(error => {
                                    reject(error);
                                });
                        });
                    } else {
                        this.$router.push({name: "catalogList"}).catch(() => {});
                    }
                }
                this.$router.push({name: "catalogList"}).catch(() => {});
            }
        },
        computed: {
            ...mapGetters(["cartId", "cartItems", "roles", "userObject"])
        }
    };
</script>

<style>
    button:hover,
    button:focus {
        outline: 0 none;
    }

    .divider-show,
    .v-navigation-drawer--mini-variant .divider-show.v-divider {
        display: block !important;
    }
    .custom-active-class {
        color: #424242 !important;
    }
    .custom-active-class div {
        color: #F15E60;
    }
</style>
